import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Call.css';

import Search from './Search';
import Map from './Map';
import Submit from './Submit';
import SearchResult from './SearchResult';
import EditAddress from '../EditAddress/EditAddress'; 

class Call extends Component {
  render() {
    return (
      <div className='call'>
        <div className='call__content'>
          <Search />
          <Map />
          <Submit />
          {
            this.props.showSearchResult ?
            <SearchResult /> :
            null
          }
          {
            this.props.showEditAddress ?
            <EditAddress /> :
            null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    showSearchResult: state.windows.searchResult.show,
    showEditAddress: state.windows.editAddress.show,
  }
}

export default connect(mapStateToProps)(Call);
