import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeSearchClient, searchClient, clearCallForm } from '../../actions/call';
import { openRegistrationForm, openEditAddress } from '../../actions/windows';
import './Search.css';
import { withTranslation } from 'react-i18next';

import edit from '../../img/edit.png';

class Search extends Component {
  onChange = event => this.props.changeSearchClient(event.target.name, event.target.value);

  onSubmit = event => {
    event.preventDefault();
    this.props.searchClient();
  }

  render() {
    const address = this.props.client !== null && this.props.client.addresses.find(e => e.type === 1) !== undefined ?
                    this.props.client.addresses.find(e => e.type === 1).freeInput : '';

    const disabled = this.props.spinner || this.props.results;
    const { t } = this.props;

    return (
      <div className='call-search'>
        <form className='call-search__form' onSubmit={ this.onSubmit }>
          <div className='form__header'>
            { t('Поиск пациента') }
          </div>
          <div className='form__group'>
            <label htmlFor='call-lastName' className='form__label'>{ t('Фамилия') }</label>
            <input id='call-lastName' name='lastName'
                   className='form__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.lastName }
                   onChange={ this.onChange }
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='call-firstName' className='form__label'>{ t('Имя') }</label>
            <input id='call-firstName' name='firstName'
                   className='form__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.firstName }
                   onChange={ this.onChange } />
          </div>
          <div className='form__group'>
            <label htmlFor='call-patrName' className='form__label'>{ t('Отчество') }</label>
            <input id='call-patrName' name='patrName'
                   className='form__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.patrName }
                   onChange={ this.onChange } />
          </div>
          <div className='form__group'>
            <label htmlFor='call-birthDate' className='form__label'>{ t('Дата рождения') }</label>
            <input id='call-birthDate' name='birthDate'
                   className='form__datepicker'
                   type='date'
                   value={ this.props.form.birthDate }
                   onChange={ this.onChange } />
          </div>
          <div className='form__group'>
            <label htmlFor='call-address' className='form__label'>{ t('Адрес') }</label>
            <div  id='call-address' name='address'
                  className='form__input form__disabled'>
              { address }
            </div>
            {
              this.props.client !== null ?
              <img className='call-address-edit__icon' src={ edit } alt='Редактировать' title={ t('Редактировать') } onClick={ this.props.openEditAddress }/> :
              null
            }
          </div>
          <div className='form__group'>
            <button type='reset' className='form__btn'
                    onClick={ this.props.clearCallForm }
                    disabled={ disabled }>{ t('Сброс') }</button>
            <button type='submit' className='form__btn'
                    disabled={ disabled }>{ t('Поиск') }</button>
          </div>
          <div className='form__msg'>
            <p className='form__msg-link' onClick={ this.props.openRegistrationForm }>
              { t('Регистрация') }
            </p>
            {
              this.props.searchStatus === 1 ?
              <p>{ t('Пациент найден') }</p> :
              null
            }
            {
              this.props.searchStatus === 2 ?
              <p>{ t('Пациент не найден') }</p> :
              null
            }
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    spinner: state.windows.spinner.show,
    results: state.windows.searchResult.show,
    searchStatus: state.callForm.searchStatus,
    form: state.callForm.searchClient,
    client: state.callForm.client
  }
}

export default withTranslation()(connect(mapStateToProps, { changeSearchClient, searchClient, openRegistrationForm, openEditAddress, clearCallForm })(Search));
