import { CHANGE_EDIT_ADDRESS_FORM, CLEAR_EDIT_ADDRESS_FORM, GET_COORDINATES_EDIT_ADDRESS } from '../constants';

const initialState = {
  coordinates: null,
	data: {
    street: '',
    kladrStreet: '',
    number: '',
    corpus: '',
    flat: ''
  },
};

export const editAddressForm = (state = initialState, action) => {
	switch (action.type) {
    case CHANGE_EDIT_ADDRESS_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value
        }
      }
    case CLEAR_EDIT_ADDRESS_FORM:
      return initialState
    case GET_COORDINATES_EDIT_ADDRESS:
      return {
        ...state,
        coordinates: action.data
      }
    default:
      return state;
  }
}