import axios                                                                 from 'axios';
import { API1, CLEAR_CHANGE_PASSWORD_FORM, HOST_NAME, HOST_PORT, PROTOCOL, } from '../constants';
import { closeChangePasswordForm, openPopup }                                from './windows';
import i18n                                                                  from '../i18n';

export const changePassword = password => {
    return (dispatch, getState) => {
        const access_token = getState().oauth.data.access_token;
        const token_type = getState().oauth.data.token_type;
        const username = getState().oauth.user.user.username;

        axios.post(
            `${PROTOCOL}//${HOST_NAME + HOST_PORT + API1}/user/resetPassword`,
            null,
            {
                headers: { Authorization: `${token_type} ${access_token}` },
                params:  {
                    username,
                    password
                },
            }
        ).then(response => {
                dispatch(openPopup(i18n.t('Password has changed')));
                dispatch(closeChangePasswordForm());
            }
        ).catch(error => {
                dispatch(openPopup(i18n.t(error.response.data.message)));
            }
        )
    }
};

export const clearChangePasswordForm = () => ({
    type: CLEAR_CHANGE_PASSWORD_FORM
});

