import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changePassword } from '../../actions/changePassword';
import { closeChangePasswordForm } from '../../actions/windows';
import './ChangePasswordForm.css';
import { withTranslation } from 'react-i18next';

class ChangePasswordForm extends Component {


    constructor(props) {
        super(props);

        this.passwordRef = React.createRef();
        this.confirmPasswordRef = React.createRef();
    }

    validatePassword = () => {
        const { t } = this.props;
        const password = this.passwordRef.current,
            confirmPassword = this.confirmPasswordRef.current;

        confirmPassword.setCustomValidity(password.value !== confirmPassword.value
                                          ? t('Passwords Don\'t Match') : '');
    };

    onSubmit = event => {
        event.preventDefault();
        this.props.changePassword(event.target.password.value);
    };

    onChange = event => this.setState({ [event.target.name]: event.target.value });

    componentDidMount() {
        this.passwordRef.current.focus();
    }

    render() {
        const { t } = this.props;

        return (
            <div className='change-password'>
                <div className='change-password__content'>
                    <button className='change-password__close'
                            onClick={this.props.closeChangePasswordForm}>x</button>
                    <form className='change-password__form' onSubmit={this.onSubmit}>
                        <div className='form__group'>
                            <label htmlFor='change-password__password'
                                   className='form__label'>{t('New Password')}</label>
                            <input id='change-password__password' name='password'
                                   className='form__input'
                                   type='password'
                                   ref={this.passwordRef}
                                   onChange={this.validatePassword}
                                   required={true}/>
                        </div>
                        <div className='form__group'>
                            <label htmlFor='change-password__confirm-password'
                                   className='form__label'>{t('Подтвердите пароль')}</label>
                            <input id='change-password__confirm-password' name='password-confirm'
                                   className='form__input'
                                   type='password'
                                   ref={this.confirmPasswordRef}
                                   onKeyUp={this.validatePassword}
                                   required={true}/>
                        </div>
                        <div className='form__group'>
                            <button type='submit'
                                    className='form__btn btn--blue'>{t('Change Password')}</button>
                        </div>

                    </form>

                </div>
            </div>

        );
    }
}

export default withTranslation()(connect(null, {
    changePassword,
    closeChangePasswordForm
})(ChangePasswordForm));