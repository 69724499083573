import { SET_LIST_FILTER, FILTER_DATE, FILTER_DOCTOR, CLEAR_FILTERS, FILTER_DEPARTMENT } from '../constants';

const initialState = {
  date: new Date().toJSON().slice(0,10),
  department: '',
  doctor: 'all'
}

export const setFilter = (state = initialState, action) => {
	switch (action.type) {
    case SET_LIST_FILTER:
      switch (action.name) {
        case FILTER_DEPARTMENT:
          return {
            ...state,
            department: action.value
          }
        case FILTER_DATE:
          return {
            ...state,
            date: action.value
          }
        case FILTER_DOCTOR:
          return {
            ...state,
            doctor: action.value
          }
        default:
          return state;
      }
    case CLEAR_FILTERS:
      return {
        date: new Date().toJSON().slice(0,10),
        department: '',
        doctor: ''
      }
    default:
      return state;
  }
}