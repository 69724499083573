import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeConfirmCancel } from '../../actions/windows';
import { cancelAppointment } from '../../actions/cancelAppointment';
import './ConfirmCancel.css';
import { withTranslation } from 'react-i18next';

class ConfirmCancel extends Component {
  input = React.createRef();

  onClick = event => {
    this.props.cancelAppointment(this.input.current.value);
  }

  render() {
    const { t } = this.props;

    return (
      <div className='confirm-cancel'>
        <div className='call-map__close' onClick={ this.props.closeConfirmCancel }>&times;</div>
        <div className='confirm-cancel__text'>
          { t('Отменить запись?') }
        </div>
        <div className='form__group confirm-cancel__reason'>
          <label htmlFor='edit-reason' className='form__label'>{ t('Причина отмены') }</label>
          <input id='edit-reason' name='reason'
                 className='form__input confirm-cancel__input'
                 type='text'
                 ref={ this.input } />
        </div>
        <div className='confirm-cancel__btn-group'>
          <div className='confirm-cancel__btn' onClick={ this.onClick }>
            { t('Да') }
          </div>
          <div className='confirm-cancel__btn' onClick={ this.props.closeConfirmCancel }>
            { t('Нет') }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    homeRequestId: state.windows.confirmCancel.homeRequestId
  }
}

export default withTranslation()(connect(mapStateToProps, { closeConfirmCancel, cancelAppointment })(ConfirmCancel));
