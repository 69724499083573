import axios from 'axios';
import _ from 'lodash';
import { signout } from './signin';
import { selectClient } from './call';
import { closeRegistrationForm } from './windows';
import { CHANGE_REGISTRATION_FORM, CLEAR_REGISTRATION_FORM, FILL_REGISTRATION_FORM, GET_COORDINATES_REGISTRATION, PROTOCOL, HOST_NAME, HOST_PORT, API1 } from '../constants';

export const changeRegistrationForm = (name, value, getCoordinates) => {
	return (dispatch, getState) => {
		dispatch({
		  type: CHANGE_REGISTRATION_FORM,
		  name,
		  value
		})

		if ((name === 'street' || name === 'number' || name === 'corpus') &&
        getState().registrationForm.data.street !== '' &&
        getState().registrationForm.data.number !== '' &&
        getCoordinates === true) {
			getCoordinatesDebounced(dispatch);
		}
	}
}

export const getCoordinates = () => {
  return (dispatch, getState) => {
    const city =   'Санкт-Петербург'
    const street = getState().registrationForm.data.street;
    const house_number = getState().registrationForm.data.number + (getState().registrationForm.data.corpus !== '' ? ' к' + getState().registrationForm.data.corpus : '');

    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT }/nominatim/search`,
      {
        params: {
          format: 'json',
          addressdetails: 1,
          'accept-language': 'ru',
          countrycodes: 'ru',
          city,
          street: `${ street } ${ house_number }`
        }
      }
    ).then(
      response => {
        const data = response.data.filter(e => e.osm_type === 'way');

        if (data.length > 0) {
          dispatch({
            type: GET_COORDINATES_REGISTRATION,
            data: {
              lat: +data[0].lat,
              lon: +data[0].lon
            }
          })
        }
        if (data.length === 0 && response.data.length > 0) {
          dispatch({
            type: GET_COORDINATES_REGISTRATION,
            data: {
              lat: +response.data[0].lat,
              lon: +response.data[0].lon
            }
          })
        }
      }
    )
  }
}

export const getCoordinatesDebounced = _.debounce(dispatch => dispatch(getCoordinates()), 1000);


export const submitRegistrationForm = () => {
  return (dispatch, getState) => {
    const data = getState().registrationForm.data;
    const access_token = getState().oauth.data.access_token;
    const token_type = getState().oauth.data.token_type;
    const hostId = getState().oauth.operator.hostId;

    const body = {
      lastName: data.lastName,
      firstName: data.firstName,
      patrName: data.patrName,
      birthDate: data.birthDate,
      sex: +(data.sex),
      addresses:[
        {
          address:{
            corpus: data.corpus,
            flat: data.flat,
            kladr: '7800000000000',
            kladrStreet: data.kladrStreet,
            number: data.number
          }
        }
      ],
      id: null,
      hostId
    }

    axios.post(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/client/create`,
      body,
      {
        headers: {
          Authorization: `${ token_type } ${ access_token }`
        }
      }
    ).then(
      response => {
        dispatch(closeRegistrationForm());
        dispatch(selectClient({ id: response.data.id }))
      }
    ).catch(
      error => {
        if (error.response && error.response.status === 401) {
          dispatch(signout())
        }
      }
    )
  }
}

export const clearRegistrationForm = () => ({
  type: CLEAR_REGISTRATION_FORM
})

export const fillRegistrationForm = data => ({
  type: FILL_REGISTRATION_FORM,
  data
})
