import React, { Component } from 'react';
import Submit from './Submit';
import Map from './Map';
import './EditAddress.css';


class EditAddress extends Component {
  render() {
    return (
      <div className='edit-address-container'>
        <Submit />
        <Map />
      </div>
    );
  }
}

export default EditAddress;
