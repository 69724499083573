import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './Language.css';

class Language extends Component {
  onChange = event => {
    this.props.i18n.changeLanguage(event.target.value);
    document.cookie = `language=${ event.target.value };`;
  }

  render() {
    return (
      <div>
        <select className='language' defaultValue={ this.props.i18n.language } onChange={ this.onChange } >
          <option value='de'>Deutsch</option>
          <option value='en'>English</option>
          <option value='ru'>Русский</option>
        </select>
      </div>
    );
  }
}

export default withTranslation()(Language);
