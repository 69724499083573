import {
  OPEN_CONFIRM_CANCEL,
  CLOSE_CONFIRM_CANCEL,
  OPEN_CALL_FORM,
  CLOSE_CALL_FORM,
  OPEN_SEARCH_RESULT,
  CLOSE_SEARCH_RESULT,
  OPEN_EDIT_FORM,
  CLOSE_EDIT_FORM,
  OPEN_REGISTRATION_FORM,
  CLOSE_REGISTRATION_FORM,
  OPEN_LIST,
  CLOSE_LIST,
  OPEN_ATTACHMENT,
  CLOSE_ATTACHMENT,
  OPEN_EDIT_ADDRESS,
  CLOSE_EDIT_ADDRESS,
  OPEN_POPUP,
  CLOSE_POPUP,
  CLEAR_WINDOWS,
  SHOW_SPINNER,
  HIDE_SPINNER,
  OPEN_BRIGADE,
  CLOSE_BRIGADE,
  OPEN_CREATE_BRIGADE,
  CLOSE_CREATE_BRIGADE,
  OPEN_CHANGE_PASSWORD_FORM,
  CLOSE_CHANGE_PASSWORD_FORM,
} from '../constants';

const initialState = {
  confirmCancel: {
    show: false
  },
  callForm: {
    show: false
  },
  searchResult: {
    show: false
  },
  editForm: {
    show: false
  },
  registrationForm: {
    show: false
  },
  list: {
    show: false
  },
  attachment: {
    show: false
  },
  editAddress: {
    show: false
  },
  popup: {
    show: false,
    message: ''
  },
  spinner: {
    show: false
  },
  brigade: {
    show: false
  },
  createBrigade: {
    show: false
  },
  changePasswordForm: {
    show: false
  }
};

export const windows = (state = initialState, action) => {
	switch (action.type) {
    case CLEAR_WINDOWS:
      return initialState
    case OPEN_CONFIRM_CANCEL:
      return {
        ...state,
        confirmCancel: {
          show: true,
          homeRequestId: action.homeRequestId
        }
      };
    case CLOSE_CONFIRM_CANCEL:
      return {
        ...state,
        confirmCancel: {
          show: false
        }
      };
    case OPEN_CALL_FORM:
      return {
        ...state,
        callForm: {
          show: true
        }
      };
    case CLOSE_CALL_FORM:
      return {
        ...state,
        callForm: {
          show: false
        },
        searchResult: {
          show: false
        },
        editAddress: {
          show: false
        }
      };
    case OPEN_SEARCH_RESULT:
      return {
        ...state,
        searchResult: {
          show: true
        }
      };
    case CLOSE_SEARCH_RESULT:
      return {
        ...state,
        searchResult: {
          show: false
        }
      };
    case OPEN_EDIT_FORM:
      return {
        ...state,
        editForm: {
          show: true
        }
      }
    case CLOSE_EDIT_FORM:
      return {
        ...state,
        editForm: {
          show: false
        }
      }
    case OPEN_REGISTRATION_FORM:
      return {
        ...state,
        registrationForm: {
          show: true
        }
      }
    case CLOSE_REGISTRATION_FORM:
      return {
        ...state,
        registrationForm: {
          show: false
        }
      }
    case OPEN_ATTACHMENT:
      return {
        ...state,
        attachment: {
          show: true
        }
      }
    case CLOSE_ATTACHMENT:
      return {
        ...state,
        attachment: {
          show: false
        }
      }
    case OPEN_LIST:
      return {
        ...state,
        list: {
          show: true
        }
      }
    case CLOSE_LIST:
      return {
        ...state,
        list: {
          show: false
        }
      }
    case OPEN_EDIT_ADDRESS:
      return {
        ...state,
        editAddress: {
          show: true
        }
      }
    case CLOSE_EDIT_ADDRESS:
      return {
        ...state,
        editAddress: {
          show: false
        }
      }
    case OPEN_POPUP:
      return {
        ...state,
        popup: {
          show: true,
          message: action.message
        }
      }
    case CLOSE_POPUP:
      return {
        ...state,
        popup: {
          show: false,
          message: ''
        }
      }
    case SHOW_SPINNER:
      return {
        ...state,
        spinner: {
          show: true
        }
      }
    case HIDE_SPINNER:
      return {
        ...state,
        spinner: {
          show: false
        }
      }
    case OPEN_BRIGADE:
      return {
        ...state,
        brigade: {
          show: true
        }
      }
    case CLOSE_BRIGADE:
      return {
        ...state,
        brigade: {
          show: false
        }
      }
    case OPEN_CREATE_BRIGADE:
      return {
        ...state,
        createBrigade: {
          show: true
        }
      }
    case CLOSE_CREATE_BRIGADE:
      return {
        ...state,
        createBrigade: {
          show: false
        }
      }
      case OPEN_CHANGE_PASSWORD_FORM:
        return {
          ...state,
          changePasswordForm: {
            show: true
          }
        }
      case CLOSE_CHANGE_PASSWORD_FORM:
        return {
          ...state,
          changePasswordForm: {
            show: false
          }
        }
    default:
      return state;
  }
}