import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FILTER_DATE, FILTER_DOCTOR, FILTER_DEPARTMENT } from '../../constants';
import { setFilter } from '../../actions/setFilter';
import { updateList, updateLists, updateTodayList } from '../../actions/updateList';
import { openCallForm, openBrigade } from '../../actions/windows';
import './ListHeader.css';
import { withTranslation } from 'react-i18next';

class ListHeader extends Component {

  state = {
    date: this.props.date,
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.date !== this.state.date) && (!isNaN(Date.parse(nextProps.date )))) {
      this.setState({ date: this.formatDate(nextProps.date) });
    }
  }

  formatDate = date => new Date(date).toISOString().split('T')[0];
  
  changeDate = (event, newDate) => {
    if (newDate) {
      this.props.setFilter(FILTER_DATE, newDate);
    } else {
      this.props.setFilter(FILTER_DATE, event.target.value);
    }
    
    this.props.updateList();
  }

  switchDate = newDate => {
    this.setState({
      date: this.formatDate(newDate)
    })
    this.changeDate(null, this.formatDate(newDate));
  }

  incDate = () => {
      const oldDate = new Date(this.state.date);
      const newDate = oldDate.setDate(oldDate.getDate() + 1);
    this.switchDate(newDate);
  }

  decDate = () => {
    var oldDate = new Date(this.state.date);
    const newDate = oldDate.setDate(oldDate.getDate() - 1);
    this.switchDate(newDate);
  } 

  refresh = () => {
    if (this.props.date === new Date().toJSON().slice(0,10)) {
      this.props.updateLists();
    } else {
      this.props.updateList();
      this.props.updateTodayList();
    }
  }

  changeDepartment = event => this.props.setFilter(FILTER_DEPARTMENT, event.target.value)
  changeDoctor = event => this.props.setFilter(FILTER_DOCTOR, event.target.value)

  render() {
    const { t } = this.props;
    const smp = this.props.role === 'SMP_REGISTRATOR';
    
    return (
      <div className='list-header'>
        <span className='list-header__datepicker_wrapper'>
          <button className="datepicker_arrow prev" onClick={ this.decDate }>◄</button>
          <input  className='list-header__datepicker'
                  type='date'
                  value={ this.state.date }
                  onChange={ this.changeDate }/>
          <button className="datepicker_arrow next" onClick={ this.incDate }>►</button>
        </span>        
        <select  className='list-header__select'
                  value={ this.props.doctor }
                  onChange={ this.changeDoctor } >
          <option value='all'>{ t('Все врачи') }</option>
          <option value=''>{ t('Без врача') }</option>
          {
            this.props.coordinates.map(
              e => <option key={ e.id } value={ e.id }>{ e.fio.split(' ')[0] }</option>
            )
          }
        </select>
        <select  className='list-header__select'
                  value={ this.props.department }
                  onChange={ this.changeDepartment } >
          <option value=''>{ t('Все отделения') }</option>
          {
            this.props.orgStructures.map(
              e => <option key={ e.id } value={ e.id }>{ e.code }</option>
            )
          }
        </select>
        {smp && <button className='list-header__refresh'
                onClick={ this.props.openBrigade } >
                { t('Бригады') }
              </button>
        }
        <button className='list-header__refresh'
                onClick={ this.refresh } >
          { t('Обновить') }
        </button>

        <div  className='list-header__btn'
              onClick={ this.props.openCallForm }>
          { t('Добавить вызов') }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    date: state.listFilter.date,
    department: state.listFilter.department,
    doctor: state.listFilter.doctor,
    coordinates: state.coordinates.data,
    orgStructures: state.list.orgStructures,
    role: state.oauth.user ? state.oauth.user.type : ''
  }
}

export default withTranslation()(connect(mapStateToProps, { setFilter, updateList, updateLists, updateTodayList, openCallForm, openBrigade })(ListHeader));
