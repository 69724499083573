import React, { Component } from 'react';
import './Edit.css';

import Map from './Map';
import Patient from './Patient';
import Submit from './Submit';

class Edit extends Component {
  render() {
    return (
      <div className='edit'>
        <div className='edit__content'>
          <Patient />
          <Map />
          <Submit />
        </div>
      </div>
    );
  }
}

export default Edit;
