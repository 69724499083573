import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeEditForm } from '../../actions/windows';
import { changeSubmitClient } from '../../actions/edit';
import './Map.css';
import { withTranslation } from 'react-i18next';

import olMap from 'ol/Map';
import View from 'ol/View';
import Zoom from 'ol/control/Zoom'

import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {SHOW_MAP_WITH_PROXY} from '../../constants'
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';

import Select from 'ol/interaction/Select'
import { click as clickEvent } from 'ol/events/condition'

import { extend } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

import marker from '../../img/marker.png';
import markerDoc from '../../img/marker-doc.png';


class Map extends Component {
	initMap = () => {
		this.map = new olMap({
		  target: 'edit-map__map',
		  controls: [
    		new Zoom()
  		],
		  view: new View({
		    zoom: 15,
		    maxZoom: 17
		  })
		});

		let defaultLayer = new TileLayer({
			preload: Infinity,
			source: new OSM()
		  });

		let localLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: `https://${document.location.hostname}/map/{a-c}/{z}/{x}/{y}.png`
			})
		});

		let patientLayer = new VectorLayer({
			source: new VectorSource()
		});

		let doctorLayer = new VectorLayer({
			source: new VectorSource()
		});

		if (SHOW_MAP_WITH_PROXY) {
			this.map.addLayer(localLayer);
		} else {
			this.map.addLayer(defaultLayer);
		}

		this.map.addLayer(patientLayer);
		this.map.addLayer(doctorLayer);

  	const select = new Select({
  		condition: clickEvent,
  		layers: [this.map.getLayers().item(1)]
  	});
		select.on('select', event => {
		  this.props.changeSubmitClient('doctor', `${ event.selected[0].getId() }`);
		});
		this.map.addInteraction(select);
	}

	updatePatientPosition = () => {
		this.map.getLayers().item(2).getSource().clear();

		if (this.props.clientCoordinates !== null) {
			const feature = new Feature({
	      geometry: new Point(
	        fromLonLat([ this.props.clientCoordinates.lon, this.props.clientCoordinates.lat])
	      )
	    });

	    feature.setStyle(
	    	new Style({
		      image: new Icon({
		        anchor: [0.5, 1],
		        src: marker
		      }),
		      text: new Text({
            text: this.props.client.lastName,
            offsetY: -35,
            fill: new Fill({
            	color: 'black'
            }),
            backgroundFill: new Fill({
            	color: 'rgba(255, 255, 255, 0.9)'
            }),
           	backgroundStroke: new Stroke({
            	color: 'rgba(0, 0, 0, 0.4)',
            	width: 1
            }),
            padding: [ 5, 10, 5, 10]
	        })
		    })
		  );

			this.map.getLayers().item(2).getSource().addFeature(feature);

			this.map.getView().fit(
				extend(
					this.map.getLayers().item(1).getSource().getExtent(),
					this.map.getLayers().item(2).getSource().getExtent()
				),
				{
					padding: [ 50, 50, 50, 50 ]
				}
  		);
		}
	}

	updateDoctorPosition = () => {
		this.map.getLayers().item(1).getSource().clear();

		this.props.doctorCoordinates.filter(e => e.last_point !== null).forEach(
			e => {
				const feature = new Feature({
		      geometry: new Point(
		        fromLonLat([ e.last_point.long, e.last_point.lat])
		      )
		    });

		    feature.setStyle(
		    	new Style({
			      image: new Icon({
			        anchor: [0.5, 1],
			        src: markerDoc
			      }),
			      text: new Text({
	            text: e.fio.split(' ')[0],
	            offsetY: -35,
	            fill: new Fill({
	            	color: 'black'
	            }),
	            backgroundFill: new Fill({
	            	color: 'rgba(255, 255, 255, 0.9)'
	            }),
	           	backgroundStroke: new Stroke({
	            	color: 'rgba(0, 0, 0, 0.4)',
	            	width: 1
	            }),
	            padding: [ 5, 10, 5, 10]
		        })
			    })
			  );
			  feature.setId(e.id);


	  		this.map.getLayers().item(1).getSource().addFeature(feature);
			}
		);
  }

	componentDidMount() {
		this.initMap();
		this.updateDoctorPosition();

		if (this.map.getLayers().item(1).getSource().getFeatures().length > 0) {
			this.map.getView().fit(
				this.map.getLayers().item(1).getSource().getExtent(),
				{
					padding: [ 50, 50, 50, 50 ]
				}
			);
		} else {
			this.map.getView().setCenter(fromLonLat([ 30.313810,	59.940266 ]));
		}
	}

	componentDidUpdate(prevProps) {
	  this.updateDoctorPosition();
	  this.updatePatientPosition();
	}

  render() {
  	const { t } = this.props;

  	return (
      <div className='call-map'>
      	<div className='call-map__close' onClick={ this.props.closeEditForm }>&times;</div>
      	<div className='call-map__legend'>
      		<img className='map__icon' src={ markerDoc } alt={ t('Врач') } title={ t('Врач') } />
      		<span> — { t('Врач') },&nbsp;&nbsp;&nbsp;&nbsp;</span>
      		<img className='map__icon' src={ marker } alt={ t('Врач') } title={ t('Врач') } />
      		<span> — { t('Пациент') }</span>
      	</div>
        <div id='edit-map__map'></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  	client: state.editForm.client,
  	clientCoordinates: state.editForm.coordinates,
    doctorCoordinates: state.coordinates.data
  }
}

export default withTranslation()(connect(mapStateToProps, { closeEditForm, changeSubmitClient })(Map));
