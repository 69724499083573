import axios from 'axios';
import { updateList } from './updateList';
import { closeEditForm, showSpinner, hideSpinner } from './windows';
import { CLEAR_EDIT_FORM, FILL_EDIT_FORM, EDIT_CHANGE_SUBMIT_CLIENT, GET_COORDINATES_EDIT, PROTOCOL, HOST_NAME, HOST_PORT, API2 } from '../constants';

export const clearEditForm = () => ({
  type: CLEAR_EDIT_FORM
})

export const fillEditForm = data => ({
  type: FILL_EDIT_FORM,
  data
})

export const changeSubmitClient = (name, value) => ({
  type: EDIT_CHANGE_SUBMIT_CLIENT,
  name,
  value
})

export const getCoordinates = client => {
  return (dispatch, getState) => {
    const address = client.addresses.find(e => e.type === 1) !== undefined ?
                    client.addresses.find(e => e.type === 1).address :
                    null;

    if (address !== null) {
      const city =   address.readableAddress.split(', ')[0].split(' ')[1];
      const street = address.readableAddress.split(', ')[1].split(' ').slice(1, -1).join(' ');
      const house_number = address.number + (address.corpus !== '' ? ' к' + address.corpus : '');

      axios.get(
        `${ PROTOCOL }//${ HOST_NAME + HOST_PORT }/nominatim/search`,
        {
          params: {
            format: 'json',
            addressdetails: 1,
            'accept-language': 'ru',
            countrycodes: 'ru',
            city,
            street: `${ street } ${ house_number }`
          }
        }
      ).then(
        response => {
          const data = response.data.filter(e => e.osm_type === 'way');

          if (data.length > 0) {
            dispatch({
              type: GET_COORDINATES_EDIT,
              data: {
                lat: +data[0].lat,
                lon: +data[0].lon
              }
            })
          }
          if (data.length === 0 && response.data.length > 0) {
            dispatch({
              type: GET_COORDINATES_EDIT,
              data: {
                lat: +response.data[0].lat,
                lon: +response.data[0].lon
              }
            })
          }
        }
      )
    }
  }
}

export const submitEditForm = () => {
  return (dispatch, getState) => {
    const homeRequestId = getState().editForm.homeRequestId;
    const claim = getState().editForm.submitClient.claim;
    const department = getState().editForm.submitClient.department;
    const emergencyBrigadeId = getState().editForm.submitClient.emergencyBrigadeId;
    const note = getState().editForm.submitClient.note;

    dispatch(showSpinner());
    axios.put(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest`,
      emergencyBrigadeId === '' ?
      {
        claim,
        id: homeRequestId,
        note,
        orgStructureId: +department
      } :
      {
        claim,
        id: homeRequestId,
        note,
        orgStructureId: +department,
        emergencyBrigade_id: +emergencyBrigadeId
      }
    ).then(
      response => {
        if (emergencyBrigadeId !== '') {
          return Promise.resolve();
        }

        const oldDoctorId = getState().editForm.doctorId;
        const doctorId = getState().editForm.submitClient.doctor;

        if (oldDoctorId === '' && doctorId !== '') {
          return axios.post(
            `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest/appointment`,
            {
              homeRequestId,
              doctorId: +doctorId,
              date: new Date().toJSON().slice(0,10)
            }
          )
        }
        if (oldDoctorId !== '' && doctorId !== '' && doctorId !== oldDoctorId) {
          return axios.put(
            `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest/appointment`,
            {
              homeRequestId,
              doctorId: +doctorId,
              date: new Date().toJSON().slice(0,10)
            }
          )
        }

        return Promise.resolve();
      }
    ).then(
      response => {
        dispatch(hideSpinner());
        dispatch(updateList());
        dispatch(closeEditForm());
      }
    ).catch(
      error => dispatch(hideSpinner())
    )
	}
}

export const copyHomeRequest = orgStructureId => {
  return (dispatch, getState) => {
    const homeRequestId = getState().editForm.homeRequestId;
    const request = getState().list.data.find(e => e.id === homeRequestId);

    dispatch(showSpinner());
    axios.post(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest`,
      {
        claim: request.claim,
        clientId: request.client.id,
        note: request.note,
        orgStructureId: +orgStructureId,
        operatorId: request.operator.id
      }
    ).then(
      response => {
        dispatch(hideSpinner());
        dispatch(updateList());
        dispatch(closeEditForm());
      }
    ).catch(
      error => dispatch(hideSpinner())
    )
  }
}
