import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openConfirmCancel, openEditForm } from '../../actions/windows';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';

import jquery from 'jquery';
import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.print.js';
import './DataTable.css';

import edit from '../../img/edit.png';
import cancel from '../../img/cancel.png';

class DataTable extends Component {
  table = React.createRef();

  componentDidMount() {
    const { t } = this.props;

    const columns = [
      {
        title: '№',
        width: 15,
        render: (data, type, row, meta ) => meta.row + 1
      },
      {
        title: t('Фамилия'),
        width: 70,
        data: 'client.lastName'
      },
      {
        title: t('Имя'),
        width: 70,
        data: 'client.firstName'
      },
      {
        title: t('Отчество'),
        width: 70,
        data: 'client.patrName'
      },
      {
        title: t('Дата рождения'),
        width: 70,
        data: 'client.birthDate',
        render: data => data.split('-').reverse().join('.')
      },
      {
        title: t('Адрес проживания'),
        width: 130,
        data: 'client.address',
        render: data => data.startsWith('Санкт-Петербург') ? data.split(', ').slice(1).join(', ') : data
      },
      {
        title: t('Время'),
        width: 50,
        data: 'time'
      },
      {
        title: t('Жалобы'),
        width: 180,
        data: 'claim',
        render: data => data.split(',').reduce((a, v, i) => i === 0 ? v : /\d|\s/.test(v[0]) ? a + ',' + v : a + ', ' + v)
      },
      {
        title: t('Отделение'),
        width: 70,
        data: 'orgStructure.code'
      },
      {
        title: t('Врач'),
        width: 60,
        data: 'doctor.name'
      },
      {
        title: t('Оператор'),
        width: 60,
        data: 'operator.name'
      },
      {
        title: '',
        width: 50,
        data: null,
        orderable: false,
        createdCell: (td, data) =>
          ReactDOM.render(
            <div style={ { textAlign: 'center' } }>
              <img className='list-main__icon' src={ edit } alt={ t('Редактировать') } title={ t('Редактировать') } onClick={ () => this.props.openEditForm(data.id) } />
              {
                data.typeName !== 'Отменено' ?
                <img className='list-main__icon' src={ cancel } alt={ t('Отменить') } title={ t('Отменить') } onClick={ () => this.props.openConfirmCancel(data.id) } /> :
                <img className='list-main__icon list-main__icon--hidden' src={ cancel } alt={ t('Отменить') } title={ t('Отменить') } />
              }
            </div>,
            td
          )
      }
    ];

    const table = jquery(this.table.current).DataTable({
      dom: '<"data-table-wrapper"Bt>',
      data: this.props.data,
      paging: false,
      columns,
      createdRow: (row, data) => {
        if (data.typeName === 'Отменено') {
          row.classList.add('table__row--canceled');
        }
        if (data.typeName === 'Ждет обработки') {
          row.classList.add('table__row--waiting');
        }
        if (data.typeName === 'Обработано') {
          row.classList.add('table__row--confirmed');
        }
      },
      order: [[ 6, 'asc' ]],
      buttons: [
        {
          extend: 'print',
          text: t('Печать'),
          title: '',
          exportOptions: {
            columns: [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ]
          }
        }
      ],
      language: {
        emptyTable: t('Записей нет')
      }
    });

    table.buttons().container().appendTo( '.list-header' );
  }

  shouldComponentUpdate(nextProps) {
    const table = jquery(this.table.current).DataTable();

    // Language
    const { t } = this.props;
    table.column(1).header().innerText = t('Фамилия');
    table.column(2).header().innerText = t('Имя');
    table.column(3).header().innerText = t('Отчество');
    table.column(4).header().innerText = t('Дата рождения');
    table.column(5).header().innerText = t('Адрес проживания');
    table.column(6).header().innerText = t('Время');
    table.column(7).header().innerText = t('Жалобы');
    table.column(8).header().innerText = t('Отделение');
    table.column(9).header().innerText = t('Врач');
    table.column(10).header().innerText = t('Оператор');
    table.context[0].oLanguage.sEmptyTable = t('Записей нет');
    table.buttons().text(t('Печать'));

    // Unmount React components in table
    const tbody = this.table.current.children[1];
    for (let i = 0; i < tbody.children.length; i++) {
      ReactDOM.unmountComponentAtNode(tbody.children[i].lastChild);
    }

    // Refresh table
    table.clear().rows.add(nextProps.data).draw();

    return true;
  }

  componentWillUnmount() {
    jquery(this.table.current).DataTable().destroy(true);
  }

  render() {
    return <table ref={ this.table }/>;
  }
}

export default withTranslation()(connect(null, { openConfirmCancel, openEditForm })(DataTable));
