import axios from 'axios';
import {
	OPEN_CONFIRM_CANCEL,
	CLOSE_CONFIRM_CANCEL,
	OPEN_CALL_FORM,
	CLOSE_CALL_FORM,
	OPEN_SEARCH_RESULT,
	CLOSE_SEARCH_RESULT,
	OPEN_EDIT_FORM,
	CLOSE_EDIT_FORM,
	OPEN_REGISTRATION_FORM,
  CLOSE_REGISTRATION_FORM,
  OPEN_LIST,
  CLOSE_LIST,
  OPEN_ATTACHMENT,
  CLOSE_ATTACHMENT,
  OPEN_EDIT_ADDRESS,
  CLOSE_EDIT_ADDRESS,
  OPEN_POPUP,
	CLOSE_POPUP,
	SHOW_SPINNER,
  HIDE_SPINNER,
  OPEN_BRIGADE,
  CLOSE_BRIGADE,
  OPEN_CREATE_BRIGADE,
  CLOSE_CREATE_BRIGADE,
	OPEN_CHANGE_PASSWORD_FORM,
	CLOSE_CHANGE_PASSWORD_FORM,
	CLEAR_WINDOWS,
	PROTOCOL,
  HOST_NAME,
  HOST_PORT,
  API1
}                                                      from '../constants';
import { clearCallForm }                               from './call';
import { clearEditForm, fillEditForm, getCoordinates } from './edit';
import { clearRegistrationForm, fillRegistrationForm } from './registration';
import { clearEditAddressForm }                        from './editAddress';
import { signout }                                     from './signin';
import { clearChangePasswordForm }  from './changePassword';


export const clearWindows = () => ({
	type: CLEAR_WINDOWS
})


export const openConfirmCancel = id => ({
	type: OPEN_CONFIRM_CANCEL,
	homeRequestId: id
})

export const closeConfirmCancel = () => ({
	type: CLOSE_CONFIRM_CANCEL
})


export const openCallForm = () => ({
	type: OPEN_CALL_FORM
})

export const closeCallForm = () => {
	return dispatch => {
		dispatch({ type: CLOSE_CALL_FORM });
		dispatch(clearCallForm());
	}
}


export const openEditForm = id => {
	return (dispatch, getState) => {
		const data = getState().list.data.find(e => e.id === id);

		dispatch(fillEditForm(data));
		dispatch({ type: OPEN_EDIT_FORM });

		const access_token = getState().oauth.data.access_token;
		const token_type = getState().oauth.data.token_type;
		const hostId = getState().oauth.operator.hostId;

    axios.get(
    	`${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/client/get`,
    	{
    		headers: {
          Authorization: `${ token_type } ${ access_token }`
        },
    		params: {
    			hostId,
    			clientId: data.client.id
    		}
    	}
    ).then(
      response => {
        dispatch(getCoordinates(response.data))
      }
    ).catch(
      error => {
	      if (error.response && error.response.status === 401) {
	        dispatch(signout())
	      }
	    }
    )
	}
}

export const closeEditForm = () => {
	return dispatch => {
		dispatch({ type: CLOSE_EDIT_FORM });
		dispatch(clearEditForm());
	}
}


export const openRegistrationForm = () => {
	return (dispatch, getState) => {
		const data = getState().callForm.searchClient;

		dispatch(fillRegistrationForm(data));
		dispatch({
			type: OPEN_REGISTRATION_FORM
		})
	}
}

export const closeRegistrationForm = () => {
	return dispatch => {
		dispatch(clearRegistrationForm());
		dispatch({
			type: CLOSE_REGISTRATION_FORM
		})
	}
}


export const openSearchResult = () => ({
	type: OPEN_SEARCH_RESULT
})

export const closeSearchResult = () => ({
	type: CLOSE_SEARCH_RESULT
})


export const openAttachment = () => ({
	type: OPEN_ATTACHMENT
})

export const closeAttachment = () => ({
	type: CLOSE_ATTACHMENT
})


export const openList =() => ({
	type: OPEN_LIST
})

export const closeList = () => ({
	type: CLOSE_LIST
})

export const openBrigade =() => ({
	type: OPEN_BRIGADE
})

export const closeBrigade = () => ({
	type: CLOSE_BRIGADE
})

export const openCreateBrigade =() => ({
	type: OPEN_CREATE_BRIGADE
})

export const closeCreateBrigade = () => ({
	type: CLOSE_CREATE_BRIGADE
})


export const showSpinner = () => ({
	type: SHOW_SPINNER
})

export const hideSpinner = () => ({
	type: HIDE_SPINNER
})


export const openEditAddress = () => ({
	type: OPEN_EDIT_ADDRESS
})

export const closeEditAddress = () => {
	return dispatch => {
		dispatch(clearEditAddressForm());
		dispatch({
			type: CLOSE_EDIT_ADDRESS
		})
	}
}

export const openChangePasswordForm = () => {

	return (dispatch, getState) => {
		dispatch({
			type: OPEN_CHANGE_PASSWORD_FORM,
			data: getState().oauth.user
		})
	}
}

export const closeChangePasswordForm = () => {
	return dispatch => {
		dispatch(clearChangePasswordForm());
		dispatch({
			type: CLOSE_CHANGE_PASSWORD_FORM
		})
	}
}

export const openPopup = message => {
	return dispatch => {
		dispatch({
			type: OPEN_POPUP,
			message
		})

		setTimeout(
			() => dispatch({ type: CLOSE_POPUP }),
			2000
		)
	}
}