import React, { Component } from 'react';
import './Registration.css';

import Map from './Map';
import Submit from './Submit';

class Registration extends Component {
  render() {
    return (
      <div className='registration'>
          <div className='registration__content'>
              <Submit />
              <Map />
          </div>
      </div>
    );
  }
}

export default Registration;
