import axios from 'axios';
import { openPopup, clearWindows } from './windows';
import { clearFilters } from './setFilter';
import { clearLists } from './updateList';
import { clearCoordinates } from './coordinates';
import { clearCallForm } from './call';
import { clearEditForm } from './edit';
import { clearEditAddressForm } from './editAddress';
import { clearRegistrationForm } from './registration';
import { SIGNIN_END, SIGNOUT } from '../constants';
import { SUCCESS, HOST_NAME, PROTOCOL, HOST_PORT, API1 } from '../constants';
import i18n from '../i18n';

const onSuccses = data => {
  return {
    type: SIGNIN_END,
    status: SUCCESS,
    data
  }
}

export const signin = (username, password) => {
  return dispatch => {
    axios.post(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/oauth/token`,
      null,
      {
        headers: {
          Authorization: 'Basic ZG9jdG9yYXBwOjIxQUQwRDJCRkY2RDRGQ0E5RTEyM0U5NTE2N0Y0RTE0'
        },
        params: {
          'grant_type': 'password',
          username,
          password
        }
      }
    ).then(
      response => {
        const expires = new Date(Date.now() + response.data.expires_in * 1000).toUTCString();
        document.cookie = `access_token=${ response.data.access_token }; expires=${ expires }`;
        document.cookie = `token_type=${ response.data.token_type }; expires=${ expires }`;
        document.cookie = `refresh_token=${ response.data.refresh_token }; expires=${ expires }`;
        document.cookie = `scope=${ response.data.scope }; expires=${ expires }`;
        document.cookie = `expires_in=${ response.data.expires_in }; expires=${ expires }`;

        dispatch(onSuccses(response.data));
      }
    ).catch(
      error => {
        dispatch(openPopup(i18n.t('Неверное имя пользователья или пароль')));
      }
    )
  }
}

export const signout = () => {
  return dispatch => {
    const expires = new Date().toUTCString();
    document.cookie = `access_token=; expires=${ expires }`;
    document.cookie = `token_type=; expires=${ expires }`;
    document.cookie = `refresh_token=; expires=${ expires }`;
    document.cookie = `scope=; expires=${ expires }`;
    document.cookie = `expires_in=; expires=${ expires }`;

    dispatch(clearState());
    dispatch({ type: SIGNOUT });
  }
}

const clearState = () => {
  return dispatch => {
    dispatch(clearFilters());
    dispatch(clearLists());
    dispatch(clearCoordinates());
    dispatch(clearWindows());
    dispatch(clearEditAddressForm());
    dispatch(clearCallForm());
    dispatch(clearEditForm());
    dispatch(clearRegistrationForm());
  }
}
