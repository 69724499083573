import axios from 'axios';
import { UPDATE_COORDINATES_END, CLEAR_COORDINATES } from '../constants';
import { SUCCESS, ERROR, PROTOCOL, HOST_NAME, HOST_PORT, API2 } from '../constants';


const onSuccses = data => {
  return {
    type: UPDATE_COORDINATES_END,
    status: SUCCESS,
    data
  }
}

const onError = error => {
  return {
    type: UPDATE_COORDINATES_END,
    status: ERROR,
    error
  }
}

export const clearCoordinates = () => ({
  type: CLEAR_COORDINATES
})


export const updateCoordinates = () => {
  return (dispatch, getState) => {
    if (getState().oauth.signin === true) {
      axios.get(`${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/geo/doctors`)
      .then(
        response => {
          dispatch(onSuccses(response.data));
          setTimeout(
            () => dispatch(updateCoordinates()),
            5 * 60 * 1000
          )
        }
      ).catch(
        error => dispatch(onError(error))
      )
    }
  }
}
