import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeRegistrationForm } from '../../actions/windows';
import { changeRegistrationForm } from '../../actions/registration'
import axios from 'axios';
import { PROTOCOL, HOST_NAME, HOST_PORT } from '../../constants';
import jquery from 'jquery';
import './Map.css';
import { withTranslation } from 'react-i18next';

import olMap from 'ol/Map';
import View from 'ol/View';
import Zoom from 'ol/control/Zoom'

import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

import { fromLonLat, toLonLat } from 'ol/proj';

import marker from '../../img/marker.png';


class Map extends Component {
	initMap = () => {
		this.map = new olMap({
		  target: 'registration-map__map',
		  controls: [
    		new Zoom()
  		],
		  layers: [
		    new TileLayer({
		      preload: Infinity,
		      source: new OSM({
		      	url: '/map/{a-c}/{z}/{x}/{y}.png'
		      })
		    }),
		    new VectorLayer({
		    	source: new VectorSource()
		    })
		  ],
		  view: new View({
		    center: fromLonLat([ 30.420137, 59.956569 ]),
		    zoom: 14,
		    maxZoom: 17
		  })
		});

		this.map.on('click', event => {
			const coordinates = toLonLat(event.coordinate);

			axios.get(
				`${ PROTOCOL }//${ HOST_NAME + HOST_PORT }/nominatim/reverse`,
				{
					params: {
					  format: 'json',
					  'accept-language': 'ru',
					  countrycodes: 'ru',
					  limit: 10,
					  lon: coordinates[0],
					  lat: coordinates[1]
					}
				}
			).then(
			  response => {
			  	this.map.getLayers().item(1).getSource().clear();

			  	const feature = new Feature({
			      geometry: new Point(
			        fromLonLat([ +response.data.lon, +response.data.lat ])
			      )
			    });

			    feature.setStyle(
			    	new Style({
				      image: new Icon({
				        anchor: [0.5, 1],
				        src: marker
				      })
				    })
				  );

		  		this.map.getLayers().item(1).getSource().addFeature(feature);


		  		jquery.kladr.api(
						{
							oneString: true,
							type: jquery.kladr.type.street,
							parentType: jquery.kladr.type.city,
							parentId: '7800000000000',
							query: response.data.address.road
						},
						data => {
							if (data.length > 0) {
								const number = response.data.address.house_number;

								this.props.changeRegistrationForm('street', data[0].name, false);
								this.props.changeRegistrationForm('kladrStreet', data[0].id, false);

								if (number.includes(' к')) {
									this.props.changeRegistrationForm('number', number.split(' ')[0], false);
									this.props.changeRegistrationForm('corpus', number.split('к')[1], false);
								} else {
									this.props.changeRegistrationForm('number', number, false);
								}
							}
						}
					);
			  }
			);
	  });
	}

	updatePatientPosition = () => {
		this.map.getLayers().item(1).getSource().clear();

		if (this.props.coordinates !== null) {
			const feature = new Feature({
	      geometry: new Point(
	        fromLonLat([ this.props.coordinates.lon, this.props.coordinates.lat])
	      )
	    });

	    feature.setStyle(
	    	new Style({
		      image: new Icon({
		        anchor: [0.5, 1],
		        src: marker
		      })
		    })
		  );

			this.map.getLayers().item(1).getSource().addFeature(feature);

			this.map.getView().fit(
				this.map.getLayers().item(1).getSource().getExtent(),
				{
					padding: [ 50, 50, 50, 50 ]
				}
  		);
		}
	}

	componentDidMount() {
		this.initMap();
	}

	componentDidUpdate(prevProps) {
	  this.updatePatientPosition();
	}

  render() {
  	return (
      <div className='registration-map'>
      	<div className='registration-map__close' onClick={ this.props.closeRegistrationForm }>&times;</div>
      	<div className='registration__help'>
        	{ this.props.t('Введите адрес или выберите дом на карте') }
        </div>
        <div id='registration-map__map'></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  	coordinates: state.registrationForm.coordinates
  }
}

export default withTranslation()(connect(mapStateToProps, { changeRegistrationForm, closeRegistrationForm })(Map));
