import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { PROTOCOL, HOST_NAME, HOST_PORT, API2 } from '../../constants';
import { withTranslation } from 'react-i18next';
import { closeBrigade, openCreateBrigade } from '../../actions/windows';
import { getEmergencyBrigades } from '../../actions/updateList';
import jquery from 'jquery';
import './Brigade.css';
import cancel from '../../img/cancel.png';

class Brigade extends Component {
  state = {
    id: undefined,
    showConfirmDelete: false
  }
  table = React.createRef();

  deleteBrigade = () => {
    axios.delete(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/emergencyBrigade`,
      {
        data: { id: this.state.id }
      }
    ).then(
      response => {
        this.props.getEmergencyBrigades();
        this.setState({ id: undefined, showConfirmDelete: false });
      }
    )
  }

  componentDidMount() {
    const { t } = this.props;

    const columns = [
      {
        title: '№',
        width: 20,
        render: (data, type, row, meta ) => meta.row + 1
      },
      {
        title: t('Название'),
        width: 100,
        data: 'name'
      },
      {
        title: t('Водитель'),
        width: 100,
        data: 'driver'
      },
      {
        title: t('Врач'),
        width: 100,
        data: 'doctor'
      },
      {
        title: t('Фельдшер'),
        width: 100,
        data: 'paramedic'
      },
      {
        title: '',
        width: 25,
        data: null,
        orderable: false,
        createdCell: (td, data) =>
          ReactDOM.render(
            <div style={ { textAlign: 'center' } }>
              <img  className='list-main__icon' src={ cancel }
                    alt={ t('Удалить') } title={ t('Удалить') }
                    onClick={ () => { this.setState({ id: data.id, showConfirmDelete: true }) } } />
            </div>,
            td
          )
      }
    ];

    jquery(this.table.current).DataTable({
      dom: '<"data-table-wrapper"Bt>',
      data: getBrigadeList(this.props.emergencyBrigades),
      paging: false,
      columns,
      order: [[ 0, 'asc' ]],
      buttons: [],
      language: {
        emptyTable: t('Бригад нет')
      }
    });
  }

  shouldComponentUpdate(nextProps) {
    const table = jquery(this.table.current).DataTable();

    // Unmount React components in table
    const tbody = this.table.current.children[1];
    for (let i = 0; i < tbody.children.length; i++) {
      ReactDOM.unmountComponentAtNode(tbody.children[i].lastChild);
    }

    // Refresh table
    table.clear().rows.add(getBrigadeList(nextProps.emergencyBrigades)).draw();

    return true;
  }

  componentWillUnmount() {
    jquery(this.table.current).DataTable().destroy(true);
  }

  render() {
    const { t } = this.props;

    return (
      <div className='brigade'>
      <div className='brigade__content'> 
        <div className='call-map__close' onClick={ this.props.closeBrigade }>&times;</div>
        <div className='brigade__table'>
          <table ref={ this.table } />
        </div>
        <div className='brigade__btns'>
          <div className='form__btn' onClick={ this.props.openCreateBrigade }>{ t('Добавить') }</div>
          <div className='form__btn' onClick={ this.props.closeBrigade }>{ t('Назад') }</div>
        </div>
        {
          this.state.showConfirmDelete ?
          <div className='confirm-delete'>
            <div className='call-map__close' onClick={ () => { this.setState({ id: undefined, showConfirmDelete: false }) } }>&times;</div>
            <div className='confirm-cancel__text'>
              { t('Удалить бригаду?') }
            </div>
            <div className='confirm-cancel__btn-group'>
              <div className='confirm-cancel__btn' onClick={ this.deleteBrigade }>
                { t('Да') }
              </div>
              <div className='confirm-cancel__btn' onClick={ () => { this.setState({ id: undefined, showConfirmDelete: false }) } }>
                { t('Нет') }
              </div>
            </div>
          </div> :
          null
        }
      </div>
      </div>
    );
  }
}

const getBrigadeList = data => {
  const list = [];

  for (let i = 0; i < data.length; i++) {
    const id = data[i].id;
    const name = data[i].name;
    const driver = data[i].persons.find(e => e.post.code === '6001') !== undefined ?
                   data[i].persons.find(e => e.post.code === '6001').fio.split(' ')[0] :
                   '';
    const paramedic = data[i].persons.find(e => e.post.code === '4006') !== undefined ?
                      data[i].persons.find(e => e.post.code === '4006').fio.split(' ')[0] :
                      '';
    const doctor =  data[i].persons.find(e => e.post.code === '3064') !== undefined ?
                    data[i].persons.find(e => e.post.code === '3064').fio.split(' ')[0] :
                    '';

    list.push({
      id,
      name,
      driver,
      doctor,
      paramedic,
    })
  }

  return list;
}


const mapStateToProps = state => {
  return {
    emergencyBrigades: state.list.emergencyBrigades
  }
}

export default withTranslation()(connect(mapStateToProps, { closeBrigade, openCreateBrigade, getEmergencyBrigades })(Brigade));
