import React, { Component } from 'react';
import { Switch }           from 'react-router-dom';
import ProtectedRoute       from '../containers/ProtectedRoute';
import RedirectRoute        from '../containers/RedirectRoute'
import Home                 from '../containers/Home/Home';
import Popup                from '../containers/Popup/Popup';
import Language             from '../containers/Language/Language';
import Signin               from '../containers/Signin/Signin';
import Signup               from '../containers/Signup/Signup';
import ChangePasswordForm   from '../containers/ChangePasswordForm/ChangePasswordForm';

class App extends Component {
  render() {
    return (
    	<div>
    		<Popup />
    		<Language />
	      <Switch>
	        <RedirectRoute exact path='/signin' component={ Signin } />
	        <RedirectRoute exact path='/signup' component={ Signup } />
	        <ProtectedRoute path='/' component={ Home } />
	      </Switch>
	    </div>
    );
  }
}

export default App;
