import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { PROTOCOL, HOST_NAME, HOST_PORT, API1 } from '../../constants';
import './Signup.css';
import { withTranslation } from 'react-i18next';

class Signup extends Component {

    constructor(props){
        super(props);

        this.state = { submitErr: null };
        this.passwordRef = React.createRef();
        this.confirmPasswordRef = React.createRef();
    }

    validatePassword = () => {
        const { t } = this.props;
        const password = this.passwordRef.current,
            confirmPassword = this.confirmPasswordRef.current;

        confirmPassword.setCustomValidity(password.value !== confirmPassword.value
                                          ? t('Passwords Don\'t Match') : '');
    }

	submit = event => {
		event.preventDefault();

    const data =
    {
      email: event.target.email.value,
      info: {
        firstName: event.target.firstName.value,
        lastName: event.target.lastName.value,
        patrName: event.target.patrName.value,
        type: event.target.role.value,
        speciality: event.target.speciality.value
      },
      password: event.target.password.value,
      username: event.target.username.value
    }

    axios.post(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/user/reg`,
      data
    ).then(
      response => {
        if (response.status === 200) {
          this.props.history.push('/signin');
        }
      }
    ).catch(err => this.setState({'submitErr': err.response.data.message}));
	}

  render() {
    const { t } = this.props;
    const { submitErr } = this.state;

    return (
      <div className='signup'>
          {submitErr && <div className={'singup__error-notification'}>{ t(submitErr) }</div>}
        <form className='signup__form' onSubmit={ this.submit }>
          <div className='form__group'>
            <label htmlFor='signup-username' className='form__label'>{ t('Имя пользователя') }</label>
            <input id='signup-username' name='username'
                   className='form__input'
                   type='text'
                   required={ true } />
          </div>
          <div className='form__group'>
	          <label htmlFor='signup-lastName' className='form__label'>{ t('Фамилия') }</label>
	          <input id='signup-lastName' name='lastName'
	                 className='form__input'
	                 type='text'
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='signup-firstName' className='form__label'>{ t('Имя') }</label>
	          <input id='signup-firstName' name='firstName'
	                 className='form__input'
	                 type='text'
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='signup-patrName' className='form__label'>{ t('Отчество') }</label>
	          <input id='signup-patrName' name='patrName'
	                 className='form__input'
	                 type='text'
	                 required={ true } />
	        </div>
          <div className='form__group'>
            <label htmlFor='signup-role' className='form__label'>{ t('Роль') }</label>
            <div>
              <select id='signup-role' name='role'
                      className='form__input call-submit__select'
                      required={ true } >
                <option value=''>{ t('Выберите роль') }</option>
                <option value='REGISTRATOR'>{ t('Регистратор') }</option>
                <option value='DOCTOR'>{ t('Врач') }</option>
              </select>
            </div>
          </div>
          <div className='form__group'>
            <label htmlFor='signup-speciality' className='form__label'>{ t('Специальность') }</label>
            <input id='signup-speciality' name='speciality'
                   className='form__input'
                   type='text' />
          </div>
	        <div className='form__group'>
            <label htmlFor='signup-email' className='form__label'>{ t('Электронная почта') }</label>
            <input id='signup-email' name='email'
                   className='form__input'
                   type='email'
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='signup-password' className='form__label'>{ t('Пароль') }</label>
            <input id='signup-password' name='password'
                   className='form__input'
                   type='password'
                   ref={this.passwordRef}
                   onChange={this.validatePassword}
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='signup-password-confirm' className='form__label'>{ t('Подтвердите пароль') }</label>
            <input id='signup-password-confirm' name='password-confirm'
                   className='form__input'
                   type='password'
                   ref={this.confirmPasswordRef}
                   onKeyUp={this.validatePassword}
                   required={ true } />
          </div>
          <div className='form__group'>
            <button type='submit' className='form__btn'>{ t('Зарегистрироваться') }</button>
          </div>
        </form>
        <div className='sign-footer' style={{ bottom: '1.5rem' }}>
          <Link to="/signin" className='sign-footer__link'>{ t('Войти') }</Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Signup);
