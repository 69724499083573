import { SIGNIN_END, SIGNOUT, GET_HOST_LIST, GET_OPERATOR_INFO, GET_USER_INFO } from '../constants';

export const getCookie = name => {
  const cookies = '; ' + document.cookie;
  const parts = cookies.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts[1].split(';')[0];
  }
  return '';
}

const initialState = {
  signin: getCookie('access_token') !== '',
  data: {
    access_token: getCookie('access_token'),
    expires_in: getCookie('expires_in'),
    refresh_token: getCookie('refresh_token'),
    scope: getCookie('scope'),
    token_type: getCookie('token_type')
  },
  operator: null,
  user: null,
  hosts: []
};

export const signin = (state = initialState, action) => {
	switch (action.type) {
    case SIGNIN_END:
      return {
        ...state,
        signin: true,
        data: action.data
      }
    case GET_HOST_LIST:
      return {
        ...state,
        hosts: action.data
      }
    case GET_OPERATOR_INFO:
      return {
        ...state,
        operator: action.data
      }
    case GET_USER_INFO:
      return {
        ...state,
        user: action.data
      };
    case SIGNOUT:
    	return {
        signin: false,
        data: {
          access_token: '',
          expires_in: '',
          refresh_token: '',
          scope: '',
          token_type: ''
        },
        operator: null,
        hosts: []
      }
    default:
      return state;
  }
}