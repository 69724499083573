import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeSearchResult } from '../../actions/windows';
import { selectClient, resetSearchResult } from '../../actions/call';
import './SearchResult.css';
import { withTranslation } from 'react-i18next';

class SearchResult extends Component {
	close = () => {
		this.props.resetSearchResult();
		this.props.closeSearchResult()
	}

  render() {
  	const { t } = this.props;

    return (
      <div className='search-result' style={{ height: (19 + 3 * Math.min(15, this.props.data.length)) +'rem' }} >
      	<div className='search-result__title'>
      		{ t('Найденные пациенты') }
      	</div>
      	<div className='search-result__table' style={{ height: (3 + 2.9 * Math.min(15, this.props.data.length)) +'rem' }}>
	      	<table className='list__table'>
	          <thead>
	            <tr>
	              <th className='list__head' style={{ width: '10rem' }} >
	                { t('Фамилия') }
	              </th>
	              <th className='list__head' style={{ width: '10rem' }} >
	                { t('Имя') }
	              </th>
	              <th className='list__head' style={{ width: '10rem' }} >
	                { t('Отчество') }
	              </th>
	              <th className='list__head' style={{ width: '15rem' }} >
	                { t('Дата рождения') }
	              </th>
	            </tr>
	          </thead>
	          <tbody>
	            {
	              this.props.data.map( e =>
	                <tr className='list__row'
	                    onClick={ () => { this.props.selectClient(e); this.props.closeSearchResult() } }
	                    key={ e.id } >
	                  <td className='list__cell'>
	                    { e.lastName }
	                  </td>
	                  <td className='list__cell'>
	                    { e.firstName }
	                  </td>
	                  <td className='list__cell'>
	                    { e.patrName }
	                  </td>
	                  <td className='list__cell'>
	                    { e.birthDate.split('-').reverse().join('.') }
	                  </td>
	                </tr>
	              )
	            }
	          </tbody>
	        </table>
	      </div>
	      <div className='form__group'>
            <div className='form__btn' onClick={ this.close }>{ t('Назад') }</div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  	data: state.callForm.searchResult
  }
}

export default withTranslation()(connect(mapStateToProps, { closeSearchResult, selectClient, resetSearchResult })(SearchResult));
