import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Patient extends Component {
  render() {
  	const { t } = this.props;

    return (
      <div className='call-search'>
        <form className='call-search__form'>
        	<div className='form__header'>
        		Пациент
	        </div>
	        <div className='form__group'>
	          <label htmlFor='edit-lastName' className='form__label'>{ t('Фамилия') }</label>
	          <input id='edit-lastName' name='lastName'
	                 className='form__input'
	                 type='text'
	                 value={ this.props.form.lastName }
	                 readOnly={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='edit-firstName' className='form__label'>{ t('Имя') }</label>
	          <input id='edit-firstName' name='firstName'
	                 className='form__input'
	                 type='text'
	                 value={ this.props.form.firstName }
	                 readOnly={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='edit-patrName' className='form__label'>{ t('Отчество') }</label>
	          <input id='edit-patrName' name='patrName'
	                 className='form__input'
	                 type='text'
	                 value={ this.props.form.patrName }
	                 readOnly={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='edit-birthday' className='form__label'>{ t('Дата рождения') }</label>
	          <input id='edit-birthday' name='birthday'
	                 className='form__datepicker'
	                 type='date'
	                 value={ this.props.form.birthDate }
	                 readOnly={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='call-address' className='form__label'>{ t('Адрес') }</label>
	          <div  id='call-address' name='address'
	                className='form__input form__disabled'>
	            { this.props.form.address }
	           </div>
	        </div>
	      </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.editForm.client
  }
}

export default withTranslation()(connect(mapStateToProps)(Patient));
