import { UPDATE_LIST_END, UPDATE_TODAY_LIST, GET_ORG_STRUCTURES, GET_EMERGENCY_BRIGADES, CLEAR_LISTS, SUCCESS } from '../constants';

const initialState = {
  data: [],
  today: [],
  orgStructures: [],
  emergencyBrigades: []
};

export const updateList = (state = initialState, action) => {
	switch (action.type) {
    case UPDATE_LIST_END:
      return action.status === SUCCESS
      				? { ...state, data: action.data }
      				: { ...state, data: [], error: action.error };
    case UPDATE_TODAY_LIST:
      return { ...state, today: action.data }
    case GET_ORG_STRUCTURES:
      return {
        ...state,
        orgStructures: action.data
      }
    case GET_EMERGENCY_BRIGADES:
      return {
        ...state,
        emergencyBrigades: action.data
      }
    case CLEAR_LISTS:
      return initialState
    default:
      return state;
  }
}