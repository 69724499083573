import { CHANGE_REGISTRATION_FORM, CLEAR_REGISTRATION_FORM, FILL_REGISTRATION_FORM, GET_COORDINATES_REGISTRATION } from '../constants';

const initialState = {
  coordinates: null,
	data: {
    lastName: '',
    firstName: '',
    patrName: '',
    birthDate: '',
    sex: '',
    street: '',
    kladrStreet: '',
    number: '',
    corpus: '',
    flat: ''
  },
};

export const registrationForm = (state = initialState, action) => {
	switch (action.type) {
    case CHANGE_REGISTRATION_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          [action.name]: action.value
        }
      }
    case CLEAR_REGISTRATION_FORM:
      return initialState
    case FILL_REGISTRATION_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data
        }
      }
    case GET_COORDINATES_REGISTRATION:
      return {
        ...state,
        coordinates: action.data
      }
    default:
      return state;
  }
}