import React, { Component }                                    from 'react';
import { connect }                                             from 'react-redux';
import { updateLists, getOrgStructures, getEmergencyBrigades } from '../../actions/updateList';
import { updateCoordinates }                                   from '../../actions/coordinates';
import { getOperatorInfo }                                     from '../../actions/setFilter';
import Signout                                                 from '../Signout/Signout';
import ChangePassword                                          from '../ChangePassword/ChangePasswordBtn';
import ListHeader                                              from '../ListHeader/ListHeader';
import ListMain                                                from '../ListMain/ListMain';
import Call                                                    from '../CallForm/Call';
import Edit                                                    from '../EditForm/Edit';
import Registration                                            from '../RegistrationForm/Registration';
import Attachment                                              from '../Attachment/Attachment';
import Brigade                                                 from '../Brigade/Brigade';
import CreateNewBrigade                                        from '../Brigade/CreateNewBrigade';

import spinner            from '../../img/spinner.gif';

import './Home.css';
import './Form.css';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';

class Home extends Component {
  componentDidMount() {
    this.props.getOperatorInfo();
    this.props.getOrgStructures();
    this.props.getEmergencyBrigades();
    this.props.updateLists();
    this.props.updateCoordinates();
  }

  render() {
    return (
      <div className='Home'>
        <Signout />
        <ChangePassword />
        {
          this.props.showBrigade ?
          <Brigade /> :
          null
        }
        {
          this.props.showCreateBrigade ?
          <CreateNewBrigade /> :
          null
        }
        {
          this.props.showList ?
          <div className='list-container'>
            <ListHeader />
            <ListMain />
          </div> :
           null
        }
        {
          this.props.showAttachment ?
          <Attachment /> :
          null
        }
        {
          this.props.showCallForm ?
      	  <Call /> :
          null
        }
        {
          this.props.showEditForm ?
          <Edit /> :
          null
        }
        {
          this.props.showRegistrationForm ?
          <Registration /> :
          null
        }
        {
          this.props.showChangePasswordForm ?
          <ChangePasswordForm /> :
          null
        }
        {
          this.props.showSpinner ?
          <img className='spinner' src={ spinner } alt='load' /> :
          null
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    access_token: state.oauth.data.access_token,
    showCallForm: state.windows.callForm.show,
    showEditForm: state.windows.editForm.show,
    showRegistrationForm: state.windows.registrationForm.show,
    showList: state.windows.list.show,
    showAttachment: state.windows.attachment.show,
    showSpinner: state.windows.spinner.show,
    showBrigade: state.windows.brigade.show,
    showCreateBrigade: state.windows.createBrigade.show,
    showChangePasswordForm: state.windows.changePasswordForm.show,
  }
}

export default connect(mapStateToProps, { updateLists, getOrgStructures, getEmergencyBrigades, updateCoordinates, getOperatorInfo })(Home);
