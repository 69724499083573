import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Popup.css';

class Popup extends Component {
  render() {
    return (
  		this.props.show ?
      <div className='popup'>
      	{
      		this.props.message
      	}
      </div>
      : null
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.windows.popup.show,
    message: state.windows.popup.message
  }
}

export default connect(mapStateToProps)(Popup);
