import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeEditAddressForm, submitEditAddressForm } from '../../actions/editAddress';
import { openPopup } from '../../actions/windows';
import { PROTOCOL, HOST_NAME, HOST_PORT } from '../../constants';
import './Submit.css';
import { withTranslation } from 'react-i18next';

import jquery from 'jquery';
import 'jquery.kladr/jquery.kladr.min.css';
window.jQuery = jquery;
require('jquery.kladr');


class Submit extends Component {
  street = React.createRef();

  onChange = event => this.props.changeEditAddressForm(event.target.name, event.target.value, true);

  onSubmit = event => {
    event.preventDefault();

    jquery.kladr.api(
      {
        type: jquery.kladr.type.building,
        parentType: jquery.kladr.type.street,
        parentId: this.props.form.kladrStreet,
        query: `${ this.props.form.number}${ this.props.form.corpus === '' ? '' : 'к' + this.props.form.corpus }`
      },
      data => {
        if (data.length > 0) {
          this.props.submitEditAddressForm();
        } else {
          this.props.openPopup(this.props.t('Адрес не найден'))
        }
      }
    );
  }

  componentDidMount() {
    jquery.kladr.url = `${ PROTOCOL }//${ HOST_NAME + HOST_PORT }/kladr`;

    jquery(this.street.current).kladr({
      type: jquery.kladr.type.street,
      parentType: jquery.kladr.type.city,
      parentId: '7800000000000',
      select: street => {
        this.props.changeEditAddressForm('street', street.name, true);
        this.props.changeEditAddressForm('kladrStreet', street.id, true);
      }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className='edit-address'>
        <form className='edit-address_form' onSubmit={ this.onSubmit }>
          <div className='form__header'>
            { t('Адрес') }
          </div>
          <div className='form__group'>
            <label htmlFor='edit-address-street' className='form__label'>{ t('Улица') }</label>
            <input id='edit-address-street' name='street'
                   className='form__input'
                   type='text'
                   autoComplete='off'
                   ref={ this.street }
                   value={ this.props.form.street }
                   onChange={ this.onChange }
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='edit-address-number' className='form__label'>{ t('Дом') }</label>
            <input id='edit-address-number' name='number'
                   className='form__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.number }
                   onChange={ this.onChange }
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='edit-address-corpus' className='form__label'>{ t('Корпус') }</label>
            <input id='edit-address-corpus' name='corpus'
                   className='form__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.corpus }
                   onChange={ this.onChange } />
          </div>
          <div className='form__group'>
            <label htmlFor='edit-address-flat' className='form__label'>{ t('Квартира') }</label>
            <input id='edit-address-flat' name='flat'
                   className='form__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.flat }
                   onChange={ this.onChange }
                   required={ true } />
          </div>
          <div className='form__group'>
            <button type='submit' className='form__btn'>{ t('Изменить') }</button>
            <div className='form__btn' onClick={ this.props.closeEditAddress }>{ t('Назад') }</div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.editAddressForm.data
  }
}

export default withTranslation()(connect(mapStateToProps, { changeEditAddressForm, submitEditAddressForm, openPopup })(Submit));
