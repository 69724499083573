import axios from 'axios';
import _ from 'lodash';
import { signout } from './signin';
import { closeEditAddress } from './windows';
import { CHANGE_EDIT_ADDRESS_FORM, CLEAR_EDIT_ADDRESS_FORM, GET_COORDINATES_EDIT_ADDRESS, PROTOCOL, HOST_NAME, HOST_PORT, API1 } from '../constants';

export const changeEditAddressForm = (name, value, getCoordinates) => {
	return (dispatch, getState) => {
		dispatch({
		  type: CHANGE_EDIT_ADDRESS_FORM,
		  name,
		  value
		})

		if ((name === 'street' || name === 'number' || name === 'corpus') &&
        getState().editAddressForm.data.street !== '' &&
        getState().editAddressForm.data.number !== '' &&
        getCoordinates === true) {
			getCoordinatesDebounced(dispatch);
		}
	}
}

export const getCoordinates = () => {
  return (dispatch, getState) => {
    const city =   'Санкт-Петербург'
    const street = getState().editAddressForm.data.street;
    const house_number = getState().editAddressForm.data.number + (getState().editAddressForm.data.corpus !== '' ? ' к' + getState().editAddressForm.data.corpus : '');

    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT }/nominatim/search`,
      {
        params: {
          format: 'json',
          addressdetails: 1,
          'accept-language': 'ru',
          countrycodes: 'ru',
          city,
          street: `${ street } ${ house_number }`
        }
      }
    ).then(
      response => {
        const data = response.data.filter(e => e.osm_type === 'way');

        if (data.length > 0) {
          dispatch({
            type: GET_COORDINATES_EDIT_ADDRESS,
            data: {
              lat: +data[0].lat,
              lon: +data[0].lon
            }
          })
        }
        if (data.length === 0 && response.data.length > 0) {
          dispatch({
            type: GET_COORDINATES_EDIT_ADDRESS,
            data: {
              lat: +response.data[0].lat,
              lon: +response.data[0].lon
            }
          })
        }
      }
    )
  }
}

export const getCoordinatesDebounced = _.debounce(dispatch => dispatch(getCoordinates()), 1000);


export const submitEditAddressForm = () => {
  return (dispatch, getState) => {
    const data = getState().callForm.client;
    const address = getState().editAddressForm.data;
    const access_token = getState().oauth.data.access_token;
    const token_type = getState().oauth.data.token_type;

    const body = {
      lastName: data.lastName,
      firstName: data.firstName,
      patrName: data.patrName,
      birthDate: data.birthDate,
      sex: data.sex,
      addresses:[
        {
          type: 1,
          address: {
            corpus: address.corpus,
            flat: address.flat,
            kladr: '7800000000000',
            kladrStreet: address.kladrStreet,
            number: address.number
          }
        }
      ],
      id: data.id,
      hostId: data.hostId
    }

    axios.put(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/client`,
      body,
      {
        headers: {
          Authorization: `${ token_type } ${ access_token }`
        }
      }
    ).then(
      response => dispatch(closeEditAddress())
    ).catch(
      error => {
        if (error.response && error.response.status === 401) {
          dispatch(signout())
        }
      }
    )
  }
}

export const clearEditAddressForm = () => ({
  type: CLEAR_EDIT_ADDRESS_FORM
})
