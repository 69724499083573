import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getCookie } from './reducers/signin';

const resources = {
  ru: {
    translation: {
      'title': 'Активы на дому',
      'Имя пользователя': 'Имя пользователя',
      'Пароль': 'Пароль',
      'Войти': 'Войти',
      'Выйти': 'Выйти',
      'Регистрация': 'Регистрация',
      'Зарегистрироваться': 'Зарегистрироваться',
      'Зарегистрировать': 'Зарегистрировать',
      'Фамилия': 'Фамилия',
      'Имя': 'Имя',
      'Отчество': 'Отчество',
      'Роль': 'Роль',
      'Выберите роль': 'Выберите роль',
      'Регистратор': 'Регистратор',
      'Специальность': 'Специальность',
      'Электронная почта': 'Электронная почта',
      'Подтвердите пароль': 'Подтвердите пароль',
      'Все врачи': 'Все врачи',
      'Без врача': 'Без врача',
      'Все отделения': 'Все отделения',
      'Обновить': 'Обновить',
      'Печать': 'Печать',
      'Записей нет': 'Записей нет',
      'Добавить вызов': 'Добавить вызов',
      'Дата рождения': 'Дата рождения',
      'Адрес': 'Адрес',
      'Пациент найден': 'Пациент найден',
      'Пациент не найден': 'Пациент не найден',
      'Найденные пациенты': 'Найденные пациенты',
      'Адрес проживания': 'Адрес проживания',
      'Время': 'Время',
      'Жалобы': 'Жалобы',
      'Отделение': 'Отделение',
      'Врач': 'Врач',
      'Оператор': 'Оператор',
      'Поиск пациента': 'Поиск пациента',
      'Сброс': 'Сброс',
      'Поиск': 'Поиск',
      'Примечание': 'Примечание',
      'Сохранить': 'Сохранить',
      'Назад': 'Назад',
      'Регистрация нового пациента': 'Регистрация нового пациента',
      'Пол': 'Пол',
      'М': 'М',
      'Ж': 'Ж',
      'Улица': 'Улица',
      'Дом': 'Дом',
      'Корпус': 'Корпус',
      'Квартира': 'Квартира',
      'Изменить': 'Изменить',
      'Выберите врача': 'Выберите врача',
      'Выберите отделение': 'Выберите отделение',
      'Редактировать': 'Редактировать',
      'Отменить': 'Отменить',
      'Пациент': 'Пациент',
      'Отменить запись': 'Отменить запись',
      'Причина отмены': 'Причина отмены',
      'Да': 'Да',
      'Нет': 'Нет',
      'Введите адрес или выберите дом на карте': 'Введите адрес или выберите дом на карте',
      'У вас нет ни одного прикрепленного аккаунта': 'У вас нет ни одного прикрепленного аккаунта',
      'Введите логин и пароль от своей учетной записи в МИС': 'Введите логин и пароль от своей учетной записи в МИС',
      'Хост': 'Хост',
      'Выберите хост': 'Выберите хост',
      'Прикрепить': 'Прикрепить',
      'Отменить запись?': 'Отменить запись?',
      'Неверное имя пользователья или пароль': 'Неверное имя пользователья или пароль',
      'Bad email': 'Некорректный адрес электронной почты',
      'speciality cannot be empty': 'Заполните поле "Специальность"',
      'Passwords Don\'t Match': 'Пароли не совпадают!',
      'Change Password': 'Сменить пароль',
      'New Password': 'Новый пароль',
      'Password has changed': 'Пароль успешно заменен',
    }
  },
  en: {
    translation: {
      'title': 'en title',
      'Имя пользователя': 'User name',
      'Пароль': 'Password',
      'Войти': 'Log in',
      'Выйти': 'Log out',
      'Регистрация': 'Registration',
      'Зарегистрироваться': 'Register',
      'Зарегистрировать': 'Register a patient',
      'Фамилия': 'Last name',
      'Имя': 'First name',
      'Отчество': 'Patronymic name',
      'Роль': 'Account type',
      'Выберите роль': 'Choose an account type',
      'Регистратор': 'Receptionist',
      'Специальность': 'Specialization',
      'Электронная почта': 'E-mail',
      'Подтвердите пароль': 'Confirm password',
      'Все врачи': 'All doctors',
      'Без врача': 'Without a doctor',
      'Все отделения': 'All departments',
      'Обновить': 'Refresh',
      'Печать': 'Print',
      'Записей нет': 'No records stored',
      'Добавить вызов': 'Add a call',
      'Дата рождения': 'Date of birth',
      'Адрес': 'Address',
      'Пациент найден': 'Patient is found',
      'Пациент не найден': 'Patient is not found',
      'Найденные пациенты': 'Found patients',
      'Адрес проживания': 'Residential address',
      'Время': 'Time',
      'Жалобы': 'Complaints',
      'Отделение': 'Department',
      'Врач': 'Doctor',
      'Оператор': 'Operator',
      'Поиск пациента': 'Patient search',
      'Сброс': 'Reset',
      'Поиск': 'Search',
      'Примечание': 'Note',
      'Сохранить': 'Save',
      'Назад': 'Back',
      'Регистрация нового пациента': 'Register a new patient',
      'Пол': 'Gender',
      'М': 'M',
      'Ж': 'F',
      'Улица': 'Street',
      'Дом': 'House',
      'Корпус': 'Building number',
      'Квартира': 'Apartment',
      'Изменить': 'Change',
      'Выберите врача': 'Select a doctor',
      'Выберите отделение': 'Select a department',
      'Редактировать': 'Edit',
      'Отменить': 'Cancel',
      'Пациент': 'Patient',
      'Отменить запись': 'Cancel the appointment',
      'Причина отмены': 'Cancellation reason',
      'Да': 'Yes',
      'Нет': 'No',
      'Введите адрес или выберите дом на карте': 'Please enter your address or find the house on the map',
      'У вас нет ни одного прикрепленного аккаунта': 'You do not have an account',
      'Введите логин и пароль от своей учетной записи в МИС': 'Inter your login and password in the system',
      'Хост': 'Host',
      'Выберите хост': 'Select a host',
      'Прикрепить': 'Attach',
      'Отменить запись?': 'Cancel the appointment?',
      'Неверное имя пользователья или пароль': 'The user name or password that you have entered is incorrect',
      'Bad email': 'Bad email',
      'speciality cannot be empty': 'speciality cannot be empty',
      'Passwords Don\'t Match': 'Passwords Don\'t Match!',
      'Change Password': 'Change Password',
      'New Password': 'New Password',
      'Password has changed': 'Password has changed',
    }
  },
  de: {
    translation: {
      'title': 'de title',
      'Имя пользователя': 'Nutzername',
      'Пароль': 'Passwort',
      'Войти': 'Einloggen',
      'Выйти': 'Ausloggen',
      'Регистрация': 'Anmeldung',
      'Зарегистрироваться': 'Registrieren',
      'Зарегистрировать': 'Einen Benutzer registrieren',
      'Фамилия': 'Nachname',
      'Имя': 'Vorname',
      'Отчество': 'Vatersname',
      'Роль': 'Kontotyp',
      'Выберите роль': 'Wählen Sie einen Kontotyp',
      'Регистратор': 'Rezeptionist',
      'Специальность': 'Spezialisierung',
      'Электронная почта': 'Email',
      'Подтвердите пароль': 'Passwort bestätigen',
      'Все врачи': 'Alle Ärzte',
      'Без врача': 'Ohne arzt',
      'Все отделения': 'Alle Abteilungen',
      'Обновить': 'Aktualisieren',
      'Печать': 'Drucken',
      'Записей нет': 'Keine Aufzeichnungen gespeichert',
      'Добавить вызов': 'Anruf hinzufügen',
      'Дата рождения': 'Geburtsdatum',
      'Адрес': 'Adresse',
      'Пациент найден': 'Patient wurde gefunden',
      'Пациент не найден': 'Patient wurde nicht gefunden',
      'Найденные пациенты': 'Patienten gefunden',
      'Адрес проживания': 'Wohnadresse',
      'Время': 'Zeit',
      'Жалобы': 'Beschwerden',
      'Отделение': 'Abteilung',
      'Врач': 'Arzt',
      'Оператор': 'Operator',
      'Поиск пациента': 'Patientensuche',
      'Сброс': 'Zurücksetzen',
      'Поиск': 'Suche',
      'Примечание': 'Hinweis',
      'Сохранить': 'Sparen',
      'Назад': 'Zurück',
      'Регистрация нового пациента': 'Registrieren Sie einen neuen Patienten',
      'Пол': 'Geschlecht',
      'М': 'M',
      'Ж': 'F',
      'Улица': 'Straße',
      'Дом': 'Haus',
      'Корпус': 'Hausnummer',
      'Квартира': 'Wohnung',
      'Изменить': 'Veränderung',
      'Выберите врача': 'Wählen Sie einen Arzt aus',
      'Выберите отделение': 'Wählen Sie eine Abteilung aus',
      'Редактировать': 'Bearbeiten',
      'Отменить': 'Stornieren',
      'Пациент': 'Patient',
      'Отменить запись': 'Termin abbrechen',
      'Причина отмены': 'Stornierungsgrund',
      'Да': 'Ja',
      'Нет': 'Nein',
      'Введите адрес или выберите дом на карте': 'Bitte geben Sie Ihre Adresse ein oder finden Sie das Haus auf der Karte',
      'У вас нет ни одного прикрепленного аккаунта': 'Sie haben noch kein Konto',
      'Введите логин и пароль от своей учетной записи в МИС': 'Geben Sie Ihren Benutzernamen und Ihr Passwort im System ein',
      'Хост': 'Host',
      'Выберите хост': 'Wählen Sie einen Host aus',
      'Прикрепить': 'Anfügen',
      'Отменить запись?': 'Termin abbrechen?',
      'Неверное имя пользователья или пароль': 'Der eingegebene Nutzername oder das Passwort sind nicht korrekt',
      'Bad email': 'Bad email',
      'speciality cannot be empty': 'speciality cannot be empty',
      'Passwords Don\'t Match': 'Passwords Don\'t Match!',
      'Change Password': 'Change Password',
      'New Password': 'New Password',
      'Password has changed': 'Password has changed',
    }
  },
};

const getLanguage = () => {
  const cookie = getCookie('language');
  if (cookie !== '') {
    return cookie;
  }

  const language = (window.navigator.browserLanguage || window.navigator.language).substr(0, 2);
  if (['de', 'en', 'ru'].includes(language)) {
    return language;
  }

  return 'en'
}

i18n
.use(initReactI18next)
.init(
  {
    resources,
    lng: getLanguage(),

    interpolation: {
      escapeValue: false
    }
  },
  (e, t) => { document.title = t('title'); }
);

i18n.on(
  'languageChanged',
  () => { document.title = i18n.t('title'); }
);

export default i18n;
