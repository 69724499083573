import axios from 'axios';
import {  PROTOCOL, HOST_NAME, HOST_PORT, API2 } from '../constants';
import { closeConfirmCancel } from './windows';
import { updateList, updateTodayList } from './updateList';

export const cancelAppointment= reason => {
	return (dispatch, getState) => {
    const homeRequestId = getState().windows.confirmCancel.homeRequestId;

    axios.delete(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest/appointment`,
      {
        data: {
          homeRequestId,
          reason
        }
      }
    ).then(
      response => {
        dispatch(updateList());
        dispatch(updateTodayList())
        dispatch(closeConfirmCancel());
      }
    )
  }
}
