import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

class RedirectRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route { ...rest } render={ props =>
        this.props.signin === true
          ? <Redirect to='/' />
          : <Component { ...props } />
      } />
    );
  }
}

const mapStateToProps = state => ({ signin: state.oauth.signin })

export default connect(mapStateToProps)(RedirectRoute);
