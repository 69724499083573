import { CALL_CHANGE_SEARCH_CLIENT, SEARCH_CLIENT_END, SELECT_CLIENT, CALL_CHANGE_SUBMIT_CLIENT,
         CLEAR_CALL_FORM, RESET_SEARCH_RESULT, GET_COORDINATES_CALL, SUBMIT_CALL_FORM_START, SUBMIT_CALL_FORM_END } from '../constants';
import { SUCCESS } from '../constants';

const initialState = {
  searchClient: {
    lastName: '',
    firstName: '',
    patrName: '',
    birthDate: ''
  },
  searchStatus: 0,
  searchResult: [],
  client: null,
  coordinates: null,
  submitClient: {
    claim: '',
    department: '',
    doctor: '',
    note: '',
    emergencyBrigadeId: '',
    result: ''
  },
  pending: false
};

export const callForm = (state = initialState, action) => {
	switch (action.type) {
    case CLEAR_CALL_FORM:
      return initialState;
    case CALL_CHANGE_SEARCH_CLIENT:
      return {
        ...initialState,
        submitClient: {
          ...state.submitClient
        },
        searchClient: {
          ...state.searchClient,
          [action.name]: action.value
        }
      }
    case CALL_CHANGE_SUBMIT_CLIENT:
      return {
        ...state,
        submitClient: {
          ...state.submitClient,
          [action.name]: action.value
        }
      }
    case SEARCH_CLIENT_END:
      if (action.status === SUCCESS && action.data.length > 0) {
        return {
          ...state,
          searchStatus: 1,
          searchResult: action.data
        }
      } else {
        return {
          ...state,
          searchStatus: 2,
          searchResult: []
        }
      }
    case SELECT_CLIENT:
      return {
        ...state,
        searchClient: {
          lastName: action.client.lastName,
          firstName: action.client.firstName,
          patrName: action.client.patrName,
          birthDate: action.client.birthDate
        },
        client: action.client
      }
    case GET_COORDINATES_CALL:
      return {
        ...state,
        coordinates: action.data
      }
    case RESET_SEARCH_RESULT:
      return {
        ...initialState,
        searchClient: {
          ...state.searchClient
        }
      }
    case SUBMIT_CALL_FORM_START:
      return {
        ...state,
        pending: true
      }
    case SUBMIT_CALL_FORM_END:
      return {
        ...state,
        pending: false
      }
    default:
      return state;
  }
}