import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ListMain.css';

import ConfirmCancel from '../ConfirmCancel/ConfirmCancel';
import DataTable from '../DataTable/DataTable';


class ListMain extends Component {
  render() {
    const data = this.props.data.filter(e =>  this.props.doctor === 'all' ||
                                              (this.props.doctor === '' && e.doctor.id === null) ||
                                              e.doctor.id === +this.props.doctor)
                                .filter(e => this.props.department === '' || e.orgStructure.id === +this.props.department);

    return (
      <div className='list-main'>
	      <DataTable data={ data } />
	      {
	      	this.props.showConfirmCancel ?
	      	<ConfirmCancel /> :
	      	null
	      }
    	</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.list.data,
    department: state.listFilter.department,
    doctor: state.listFilter.doctor,
    showConfirmCancel: state.windows.confirmCancel.show
  }
}

export default connect(mapStateToProps)(ListMain);
