import { UPDATE_COORDINATES_END, CLEAR_COORDINATES, SUCCESS } from '../constants';

const initialState = {
  data: []
};

export const updateCoordinates = (state = initialState, action) => {
	switch (action.type) {
    case UPDATE_COORDINATES_END:
      return action.status === SUCCESS
      				? { data: action.data }
      				: { data: [], error: action.error };
    case CLEAR_COORDINATES:
    	return initialState
    default:
      return state;
  }
}