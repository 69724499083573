import React, { Component } from 'react';
import { connect } from 'react-redux';
import jquery from 'jquery';
import { changeRegistrationForm, submitRegistrationForm } from '../../actions/registration';
import { openPopup } from '../../actions/windows';
import { PROTOCOL, HOST_NAME, HOST_PORT } from '../../constants';
import './Submit.css';
import { withTranslation } from 'react-i18next';

class Submit extends Component {
	street = React.createRef();

	onChange = event => this.props.changeRegistrationForm(event.target.name, event.target.value, true);

  onSubmit = event => {
    event.preventDefault();

    jquery.kladr.api(
      {
        type: jquery.kladr.type.building,
        parentType: jquery.kladr.type.street,
        parentId: this.props.form.kladrStreet,
        query: `${ this.props.form.number}${ this.props.form.corpus === '' ? '' : 'к' + this.props.form.corpus }`
      },
      data => {
        if (data.length > 0) {
          this.props.submitRegistrationForm();
        } else {
        	this.props.openPopup(this.props.t('Адрес не найден'))
        }
      }
    );
  }

  componentDidMount() {
  	jquery.kladr.url = `${ PROTOCOL }//${ HOST_NAME + HOST_PORT }/kladr`;

  	jquery(this.street.current).kladr({
			type: jquery.kladr.type.street,
			parentType: jquery.kladr.type.city,
			parentId: '7800000000000',
			select: street => {
				this.props.changeRegistrationForm('street', street.name, true);
				this.props.changeRegistrationForm('kladrStreet', street.id, true);
			}
		});
  }

  render() {
  	const { t } = this.props;

    return (
      <div className='registration-submit'>
        <form className='registration-submit__form' onSubmit={ this.onSubmit }>
	        <div className='form__header'>
	          { t('Регистрация нового пациента') }
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-lastName' className='form__label'>{ t('Фамилия') }</label>
	          <input id='registration-lastName' name='lastName'
	                 className='form__input'
	                 type='text'
	                 autoComplete='off'
	                 value={ this.props.form.lastName }
	                 onChange={ this.onChange }
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-firstName' className='form__label'>{ t('Имя') }</label>
	          <input id='registration-firstName' name='firstName'
	                 className='form__input'
	                 type='text'
	                 autoComplete='off'
	                 value={ this.props.form.firstName }
	                 onChange={ this.onChange }
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-patrName' className='form__label'>{ t('Отчество') }</label>
	          <input id='registration-patrName' name='patrName'
	                 className='form__input'
	                 type='text'
	                 autoComplete='off'
	                 value={ this.props.form.patrName }
	                 onChange={ this.onChange }
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-birthDate' className='form__label'>{ t('Дата рождения') }</label>
	          <input id='registration-birthDate' name='birthDate'
	                 className='form__datepicker'
	                 type='date'
	                 value={ this.props.form.birthDate }
	                 onChange={ this.onChange }
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <div className='form__label'>{ t('Пол') }</div>
	          <div className='registration-sex__input-container'>
	          	<div className='registration-sex__input'>
							  <input 	id='registration-male' name='sex'
							  				className='registration-radio'
							  				type='radio'
							  				value='1'
							  				onChange={ this.onChange }
							  				required={ true } />
							  <label htmlFor='registration-male' className='registration-sex__label'>
							  	<span className='registration-radio-button'></span>
							  	{ t('М') }
							  </label>
							</div>
							<div className='registration-sex__input'>
							  <input  id='registration-female' name='sex'
							  				className='registration-radio'
							  				type='radio'
							  				value='2'
							  				onChange={ this.onChange } />
							  <label htmlFor='registration-female' className='registration-sex__label'>
							  	<span className='registration-radio-button'></span>
							  	{ t('Ж') }
							  </label>
							</div>
						</div>
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-street' className='form__label'>{ t('Улица') }</label>
	          <input id='registration-street' name='street'
	                 className='form__input'
	                 type='text'
	                 autoComplete='off'
	                 ref={ this.street }
	                 value={ this.props.form.street }
	                 onChange={ this.onChange }
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-number' className='form__label'>{ t('Дом') }</label>
	          <input id='registration-number' name='number'
	                 className='form__input'
	                 type='text'
	                 autoComplete='off'
	                 value={ this.props.form.number }
	                 onChange={ this.onChange }
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-corpus' className='form__label'>{ t('Корпус') }</label>
	          <input id='registration-corpus' name='corpus'
	                 className='form__input'
	                 type='text'
	                 autoComplete='off'
	                 value={ this.props.form.corpus }
	                 onChange={ this.onChange } />
	        </div>
	        <div className='form__group'>
	          <label htmlFor='registration-flat' className='form__label'>{ t('Квартира') }</label>
	          <input id='registration-flat' name='flat'
	                 className='form__input'
	                 type='text'
	                 autoComplete='off'
	                 value={ this.props.form.flat }
	                 onChange={ this.onChange }
	                 required={ true } />
	        </div>
	        <div className='form__group'>
	          <button type='submit' className='form__btn'>{ t('Зарегистрировать') }</button>
	        </div>
	      </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.registrationForm.data
  }
}

export default withTranslation()(connect(mapStateToProps, { changeRegistrationForm, submitRegistrationForm, openPopup })(Submit));
