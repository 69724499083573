import { combineReducers } from 'redux'

import { signin } from './signin';
import { setFilter } from './setFilter';
import { updateList } from './updateList';
import { updateCoordinates } from './coordinates';
import { windows } from './windows';
import { callForm } from './call';
import { editForm } from './edit';
import { registrationForm } from './registration';
import { editAddressForm } from './editAddress';

export default combineReducers({
	oauth: signin,
	listFilter: setFilter,
	list: updateList,
	coordinates: updateCoordinates,
	windows,
	callForm,
	editForm,
	registrationForm,
	editAddressForm
})