import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { changeSubmitClient, submitCallForm } from '../../actions/call';
import { closeCallForm } from '../../actions/windows';
import { PROTOCOL, HOST_NAME, HOST_PORT, API2 } from '../../constants';
import './Submit.css';
import { withTranslation } from 'react-i18next';

class Submit extends Component {
  state = {
    serverCurrentTime: ''
  }

  onChange = event => this.props.changeSubmitClient(event.target.name, event.target.value);

  onSubmit = event => {
    event.preventDefault();
    this.props.submitCallForm();
  }

  componentDidMount() {
    axios.get(`${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/serverCurrentTime`)
    .then(
      response => this.setState({ serverCurrentTime: response.data.serverDatetime.substring(0, 16).replace(' ', 'T') })
    );
    
    if (this.props.role === 'SMP_REGISTRATOR') {
      this.props.changeSubmitClient('department', this.props.orgStructures.find(e => e.code === 'ОСМП').id);
    }
  }

  render() {
    const { t } = this.props;

    const smp = this.props.role === 'SMP_REGISTRATOR';
    const divStyle = {
      height: smp ? '32rem' : '30rem'
    }
    const formStyle = {
      height: smp ? '25rem' : '23rem'
    }

    return (
      <div className='call-submit' style={ divStyle }>
        <form className='call-submit__form' onSubmit={ this.onSubmit } style={ formStyle }>
          <div className='form__group'>
            <label htmlFor='call-claim' className='form__label'>{ t('Жалобы') }</label>
            <input id='call-claim' name='claim'
                   className='form__input call-submit__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.claim}
                   onChange={ this.onChange }
                   disabled={ this.props.client === null }
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='call-department' className='form__label'>{ t('Отделение') }</label>
            <div className='call-submit__input'>
              <select id='call-department' name='department'
                      className='form__input call-submit__select'
                      value={ this.props.form.department }
                      onChange={ this.onChange }
                      disabled={ this.props.client === null }
                      required={ true } 
                      placeholder= {t('Выберите отделение') }>
                {/* <option value=''>{ t('Выберите отделение') }</option> */}
                {
                  this.props.orgStructures.map(
                    e => <option key={ e.id } value={ e.id }>{ e.code }</option>
                  )
                }
              </select>
            </div>
          </div>
          {
            !smp ?
            <div className='form__group'>
              <label htmlFor='call-doctor' className='form__label'>{ t('Врач') }</label>
              <div className='call-submit__input'>
                <select id='call-doctor' name='doctor'
                        className='form__input call-submit__select'
                        value={ this.props.form.doctor }
                        onChange={ this.onChange }
                        disabled={ this.props.client === null } >
                  <option value=''>{ t('Выберите врача') }</option>
                  {
                    this.props.coordinates.map(
                      e => <option value={ e.id } key={ e.id }>{ e.fio.split(' ')[0] }</option>
                    )
                  }
                </select>
              </div>
            </div> :
            null
          }
          <div className='form__group'>
            <label htmlFor='call-note' className='form__label'>{ t('Примечание') }</label>
            <input id='call-note' name='note'
                   className='form__input call-submit__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.note }
                   onChange={ this.onChange }
                   disabled={ this.props.client === null } />
          </div>
          {
            smp ?
            <div className='form__group'>
              <label className='form__label'>{ t('Принято') }</label>
              <div className='call-submit__input'>
                <input  className='form__datepicker'
                        type={ this.state.serverCurrentTime === '' ? 'text' : 'datetime-local' }
                        value={ this.state.serverCurrentTime }
                        readOnly={ true } />
              </div>
            </div> :
            null
          }
          {
            smp ?
            <div className='form__group'>
              <label htmlFor='call-emergency-brigade' className='form__label'>{ t('Номер бригады') }</label>
              <div className='call-submit__input'>
                <select id='call-emergency-brigade' name='emergencyBrigadeId'
                        className='form__input call-submit__select'
                        value={ this.props.form.emergencyBrigadeId }
                        onChange={ this.onChange }
                        disabled={ this.props.client === null }>
                  {
                    this.props.emergencyBrigades.map(
                      e => <option value={ e.id } key={ e.id }>{ e.name }</option>
                    )
                  }
                </select>
              </div>
            </div> :
            null
          }
          <div className='form__group'>
            <button type='submit' className='form__btn'
                    disabled={ this.props.client === null || this.props.pending } >{ t('Сохранить') }</button>
            <div className='form__btn' onClick={ this.props.closeCallForm }>{ t('Назад') }</div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchStatus: state.callForm.searchStatus,
    form: state.callForm.submitClient,
    client: state.callForm.client,
    pending: state.callForm.pending,
    coordinates: state.coordinates.data,
    orgStructures: state.list.orgStructures,
    emergencyBrigades: state.list.emergencyBrigades,
    role: state.oauth.user.type
  }
}

export default withTranslation()(connect(mapStateToProps, { changeSubmitClient, closeCallForm, submitCallForm })(Submit));
