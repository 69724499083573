import React, { Component }       from 'react';
import { connect }                from 'react-redux';
import './ChangePasswordBtn.css';
import { withTranslation }        from 'react-i18next';
import { openChangePasswordForm } from '../../actions/windows';

class ChangePasswordBtn extends Component {
    render() {
        return (
            <button className={'change-password-btn'}
                    onClick={this.props.openChangePasswordForm}>{this.props.t('Change Password')}</button>
        );

    }
}


export default withTranslation()(connect(null, { openChangePasswordForm })(ChangePasswordBtn));