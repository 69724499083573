import axios from 'axios';
import md5 from 'md5';
import { signout } from './signin';
import {
	openList,
	openAttachment,
	closeAttachment,
	openPopup
} from "./windows";
import i18n from "../i18n";
import {
	SET_LIST_FILTER,
	GET_HOST_LIST,
	GET_OPERATOR_INFO,
	GET_USER_INFO,
	FILTER_DEPARTMENT,
	CLEAR_FILTERS,
	PROTOCOL,
	HOST_NAME,
	HOST_PORT,
	API1
} from "../constants";

export const setFilter = (name, value) => {
  return {
    type: SET_LIST_FILTER,
    name,
    value
  }
}

export const clearFilters = () => ({
  type: CLEAR_FILTERS
})

const getHostList = () => {
  return (dispatch, getState) => {
      const access_token = getState().oauth.data.access_token;
      const token_type = getState().oauth.data.token_type;

      axios.get(
        `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/host/all`,
        {
          headers: {
            Authorization: `${ token_type } ${ access_token }`
          }
        }
      ).then(
        response => {
          dispatch({
            type: GET_HOST_LIST,
            data: response.data
          })
        }
      ).catch(
        error => {
          if (error.response && error.response.status === 401) {
            dispatch(signout())
          }
        }
      )
  }
}

export const attachAccount = (hostId, login, password) => {
  return (dispatch, getState) => {
		const access_token = getState().oauth.data.access_token;
		const token_type = getState().oauth.data.token_type;

		axios
			.post(
				`${PROTOCOL}//${HOST_NAME + HOST_PORT + API1}/person/attach`,
				{
					hostId: +hostId,
					login,
					password: md5(password)
				},
				{
					headers: {
						Authorization: `${token_type} ${access_token}`
					}
				}
			)
			.then(response => {
				dispatch(closeAttachment());
				dispatch(getOperatorInfo());
			})
			.catch(error => {
				dispatch(openPopup(i18n.t(error.response.data.message)));
				if (error.response && error.response.status === 401) {
					dispatch(signout());
				}
			});
	};
}

export const getOperatorInfo = () => {
  return (dispatch, getState) => {
    const access_token = getState().oauth.data.access_token;
    const token_type = getState().oauth.data.token_type;

    if (access_token !== undefined) {
      axios.get(
        `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/person/get`,
        {
          headers: {
            Authorization: `${ token_type } ${ access_token }`
          }
        }
      ).then(
        response => {
          if (response.data.length === 0) {
            dispatch(getHostList());
            dispatch(openAttachment());
          } else {
            dispatch(openList());
            dispatch({
              type: GET_OPERATOR_INFO,
              data: response.data[0]
            });

            if (response.data[0].mainOrgStructureId !== null) {
              dispatch(setFilter(FILTER_DEPARTMENT, response.data[0].mainOrgStructureId));
            }
          }
        }
      ).catch(
        error => {
          if (error.response && error.response.status === 401) {
            dispatch(signout())
          }
        }
      )
    }
  }
}

export const getUserInfo = () => {
  return (dispatch, getState) => {
    const access_token = getState().oauth.data.access_token;
    const token_type = getState().oauth.data.token_type;

    if (access_token !== undefined) {
      axios.get(
        `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/user/me`,
        {
          headers: {
            Authorization: `${ token_type } ${ access_token }`
          }
        }
      ).then(
        response => {
          dispatch({
            type: GET_USER_INFO,
            data: response.data
          });

          if (response.data.type === 'SMP_REGISTRATOR') {
            const orgStructures = getState().list.orgStructures;
            dispatch(setFilter(FILTER_DEPARTMENT, orgStructures.find(e => e.code === 'ОСМП').id))
          }
        }
      ).catch(
        error => {
          if (error.response && error.response.status === 401) {
            dispatch(signout())
          }
        }
      )
    }
  }
}
