import React, { Component } from 'react';
import axios from 'axios';
import { PROTOCOL, HOST_NAME, HOST_PORT, API2 } from '../../constants';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { closeCreateBrigade } from '../../actions/windows';
import { getEmergencyBrigades } from '../../actions/updateList';
import Select from 'react-select';
import './CreateNewBrigade.css';

class CreateNewBrigade extends Component {
  state = {
    doctors: [],
    drivers: [],
    paramedics: [],
    name: '',
    driver: null,
    doctor: null,
    paramedic: null
  }

  nameChange = event => this.setState({ name: event.target.value })

  onChange = (option, select) => {
    if (select.name === 'driver') {
      this.setState({ driver: option })
    }

    if (select.name === 'doctor') {
      this.setState({ doctor: option })
    }

    if (select.name === 'paramedic') {
      this.setState({ paramedic: option })
    }
	};

	checkEmpty = () => {
		if (
			!this.state.driver ||
			this.state.driver === null ||
			((!this.state.doctor || this.state.doctor === null) &&
				(!this.state.paramedic || this.state.paramedic === null))
		) {
			return true;
		} else {
			return false;
		}
	};

  onSubmit = event => {
    event.preventDefault();

    const persons = [];
    let seniorPersonId;

    if (this.state.driver !== '') {
      persons.push({ id: +this.state.driver.value, role_id: 3 });
      seniorPersonId = +this.state.driver.value;
    }
    if (this.state.paramedic !== '') {
      persons.push({ id: +this.state.paramedic.value, role_id: 4 });
      seniorPersonId = +this.state.paramedic.value;
    }
    if (this.state.doctor !== '') {
      persons.push({ id: +this.state.doctor.value, role_id: 5 });
      seniorPersonId = +this.state.doctor.value;
    }

    if (persons.length > 0) {
      axios.post(
        `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/emergencyBrigade`,
        {
          code: this.state.name,
          codeRegional: this.state.name,
          name: this.state.name,
          persons,
          seniorPersonId
        }
      ).then(
        response => {
          this.props.getEmergencyBrigades();
          this.props.closeCreateBrigade();
        }
      )
    }
  }

  componentDidMount() {
    const compare = (a, b) => {
      if (a.fio < b.fio) {
        return -1;
      }
      if (a.fio > b.fio) {
        return 1;
      }
      return 0;
    }

    axios.get(`${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/emergencyBrigade/doctors`)
    .then(
      response => this.setState({ doctors: response.data.sort((a, b) => compare(a, b)) })
    );

    axios.get(`${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/emergencyBrigade/driver`)
    .then(
      response => this.setState({ drivers: response.data.sort((a, b) => compare(a, b)) })
    )

    axios.get(`${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/emergencyBrigade/paramedics`)
    .then(
      response => this.setState({ paramedics: response.data.sort((a, b) => compare(a, b)) })
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className='create-brigade'>
        <form className='create-brigade__form' onSubmit={ this.onSubmit }>
          <div className='form__header'>
            { t('Новая бригада') }
          </div>
          <div className='form__group'>
            <label htmlFor='brigade-name' className='form__label'>{ t('Название') }</label>
            <input id='brigade-name' name='name'
                   className='form__input'
                   type='text'
                   value={ this.state.name }
                   onChange={ this.nameChange }
                   autoComplete='off'
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='brigade-driver' className='form__label'>{ t('Водитель') }</label>
            <div className='brigade__input'>
              <Select placeholder={ t('Выберите водителя') }
                      name='driver'
                      value={ this.state.driver }
                      onChange={ this.onChange }
                      options={ getOptions(this.state.drivers) } />
            </div>
          </div>
          <div className='form__group'>
            <label htmlFor='brigade-doctor' className='form__label'>{ t('Врач') }</label>
            <div className='brigade__input'>
              <Select placeholder={ t('Выберите врача') }
                      name='doctor'
                      value={ this.state.doctor }
                      onChange={ this.onChange }
                      options={ getOptions(this.state.doctors) } />
            </div>
          </div>
          <div className='form__group'>
            <label htmlFor='brigade-paramedic' className='form__label'>{ t('Фельдшер') }</label>
            <div className='brigade__input'>
              <Select placeholder={ t('Выберите фельдшера') }
                      name='paramedic'
                      value={ this.state.paramedic }
                      onChange={ this.onChange }
                      options={ getOptions(this.state.paramedics) } />
            </div>
          </div>
          <div className='form__group'>
            <button type='submit' className='form__btn' disabled = {this.checkEmpty()}>{ t('Добавить') }</button>
            <button type='reset' className='form__btn'
                    onClick={ this.props.closeCreateBrigade }>{ t('Назад') }</button>
          </div>
        </form>
      </div>
    );
  }
}

const getOptions = data => {
  const options = [];

  for (let i = 0; i < data.length; i++) {
    options.push({ value: data[i].id, label: data[i].fio.split(' ')[0] })
  }

  return options;
}

export default withTranslation()(connect(null, { closeCreateBrigade, getEmergencyBrigades })(CreateNewBrigade));
