import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signout } from '../../actions/signin';
import './Signout.css';
import { withTranslation } from 'react-i18next';

class Signout extends Component {
  render() {
    return (
      <div className='signout' onClick={ this.props.signout }>
       	{ this.props.t('Выйти') }
     </div>
   );
 	}
}

export default withTranslation()(connect(null, { signout })(Signout));
