import { CLEAR_EDIT_FORM, FILL_EDIT_FORM, EDIT_CHANGE_SUBMIT_CLIENT, GET_COORDINATES_EDIT } from '../constants';

const initialState = {
  client: null,
  homeRequestId: null,
  coordinates: null,
  doctorId: null,
  submitClient: {
    claim: '',
    department: '',
    doctor: '',
    note: ''
  }
};

export const editForm = (state = initialState, action) => {
	switch (action.type) {
    case CLEAR_EDIT_FORM:
      return initialState
    case FILL_EDIT_FORM:
      return {
        ...state,
        client: action.data.client,
        homeRequestId: action.data.id,
        doctorId: action.data.doctor.id === null ? '' : `${ action.data.doctor.id }`,
        submitClient: {
          ...state.submitClient,
          claim: action.data.claim,
          note: action.data.note,
          department: action.data.orgStructure.id,
          emergencyBrigadeId: action.data.emergencyBrigadeId === null ? '' : `${ action.data.emergencyBrigadeId }`,
          datetimeOfBrigadeAppointed: action.data.datetimeOfBrigadeAppointed === null 
                                          ? '' 
                                          : new Date(action.data.datetimeOfBrigadeAppointed ).toISOString().slice(0, -5),
          datetimeOfBrigadeEnd: action.data.datetimeOfBrigadeEnd === null ? '' : action.data.datetimeOfBrigadeEnd.replace(' ', 'T'),
          datetimeOfTransferToOsmp: action.data.datetimeOfTransferToOsmp === null ? '' : action.data.datetimeOfTransferToOsmp.replace(' ', 'T'),
          doctor: action.data.doctor.id === null ? '' : `${ action.data.doctor.id }`
        }
      }
    case EDIT_CHANGE_SUBMIT_CLIENT:
      return {
        ...state,
        submitClient: {
          ...state.submitClient,
          [action.name]: action.value
        }
      }
    case GET_COORDINATES_EDIT:
      return {
        ...state,
        coordinates: action.data
      }
    default:
      return state;
  }
}