import axios from 'axios';
import { UPDATE_LIST_END, UPDATE_TODAY_LIST, GET_ORG_STRUCTURES, GET_EMERGENCY_BRIGADES, CLEAR_LISTS } from '../constants';
import { SUCCESS, PROTOCOL, HOST_NAME, HOST_PORT, API2 } from '../constants';
import { showSpinner, hideSpinner } from './windows';
import { getUserInfo } from './setFilter';

const onSuccses = data => {
  return {
    type: UPDATE_LIST_END,
    status: SUCCESS,
    data
  }
}

export const updateList = () => {
  return (dispatch, getState) => {
    const date = getState().listFilter.date;

    dispatch(showSpinner());
    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest`,
      {
        params: { date }
      }
    ).then(
      response => {
        dispatch(hideSpinner());
        dispatch(onSuccses(response.data));
      }
    ).catch(
      error => dispatch(hideSpinner())
    )
  }
}

export const clearLists = () => ({
  type: CLEAR_LISTS
})

export const updateTodayList = () => {
  return dispatch => {
    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest`,
      {
        params: { date: new Date().toJSON().slice(0,10) }
      }
    ).then(
      response => dispatch({
        type: UPDATE_TODAY_LIST,
        data: response.data.filter(e => e.typeName !== 'Отменено').map(e => e.client.id)
      })
    )
  }
}

export const updateLists = () => {
  return dispatch => {
    dispatch(showSpinner());
    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest`,
      {
        params: { date: new Date().toJSON().slice(0,10) }
      }
    ).then(
      response => {
        dispatch(hideSpinner())
        dispatch(onSuccses(response.data));

        dispatch({
          type: UPDATE_TODAY_LIST,
          data: response.data.filter(e => e.typeName !== 'Отменено').map(e => e.client.id)
        });
      }
    ).catch(
      error => dispatch(hideSpinner())
    )
  }
}

export const getOrgStructures = () => {
  return dispatch => {
    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/mainDepartments`
    ).then(
      response => {
        dispatch({
          type: GET_ORG_STRUCTURES,
          data: response.data
        });

        dispatch(getUserInfo());
      }
    )
  }
}

export const getEmergencyBrigades = () => {
  return dispatch => {
    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/emergencyBrigade`
    ).then(
      response => dispatch({
        type: GET_EMERGENCY_BRIGADES,
        data: response.data
      })
    )
  }
}