export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

export const SIGNIN_END = 'SIGNIN_END';
export const SIGNOUT = 'SIGNOUT';
export const CLEAR_WINDOWS = 'CLEAR_WINDOWS';

export const CLEAR_LISTS = 'CLEAR_LISTS';
export const UPDATE_LIST_END = 'UPDATE_LIST_END';
export const UPDATE_TODAY_LIST = 'UPDATE_TODAY_LIST';
export const GET_ORG_STRUCTURES = 'GET_ORG_STRUCTURES';
export const GET_EMERGENCY_BRIGADES = 'GET_EMERGENCY_BRIGADES';
export const SEARCH_CLIENT_END = 'SEARCH_CLIENT_END';

export const SET_LIST_FILTER = 'SET_LIST_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const FILTER_DATE = 'FILTER_DATE';
export const FILTER_DEPARTMENT = 'FILTER_DEPARTMENT';
export const FILTER_DOCTOR = 'FILTER_DOCTOR';

export const OPEN_CONFIRM_CANCEL = 'OPEN_CONFIRM_CANCEL';
export const CLOSE_CONFIRM_CANCEL = 'CLOSE_CONFIRM_CANCEL';

export const SUBMIT_CALL_FORM_START = 'SUBMIT_CALL_FORM_START';
export const SUBMIT_CALL_FORM_END = 'SUBMIT_CALL_FORM_END';
export const CLEAR_CALL_FORM = 'CLEAR_CALL_FORM';
export const OPEN_CALL_FORM = 'OPEN_CALL_FORM';
export const CLOSE_CALL_FORM = 'CLOSE_CALL_FORM';
export const CALL_CHANGE_SEARCH_CLIENT = 'CALL_CHANGE_SEARCH_CLIENT';
export const CALL_CHANGE_SUBMIT_CLIENT = 'CALL_CHANGE_SUBMIT_CLIENT';
export const GET_COORDINATES_CALL = 'GET_COORDINATES_CALL';


export const CLEAR_EDIT_FORM = 'CLEAR_EDIT_FORM';
export const FILL_EDIT_FORM = 'FILL_EDIT_FORM';
export const OPEN_EDIT_FORM = 'OPEN_EDIT_FORM';
export const CLOSE_EDIT_FORM = 'CLOSE_EDIT_FORM';
export const EDIT_CHANGE_SUBMIT_CLIENT = 'EDIT_CHANGE_SUBMIT_CLIENT';
export const GET_COORDINATES_EDIT = 'GET_COORDINATES_EDIT';


export const OPEN_SEARCH_RESULT = 'OPEN_SEARCH_RESULT';
export const CLOSE_SEARCH_RESULT = 'CLOSE_SEARCH_RESULT';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';
export const SELECT_CLIENT = 'SELECT_CLIENT';

export const OPEN_REGISTRATION_FORM = 'OPEN_REGISTRATION_FORM';
export const CLOSE_REGISTRATION_FORM = 'CLOSE_REGISTRATION_FORM';
export const CHANGE_REGISTRATION_FORM = 'CHANGE_REGISTRATION_FORM';
export const CLEAR_REGISTRATION_FORM = 'CLEAR_REGISTRATION_FORM';
export const FILL_REGISTRATION_FORM = 'FILL_REGISTRATION_FORM';
export const GET_COORDINATES_REGISTRATION = 'GET_COORDINATES_REGISTRATION';


export const CHANGE_EDIT_ADDRESS_FORM = 'CHANGE_EDIT_ADDRESS_FORM';
export const CLEAR_EDIT_ADDRESS_FORM = 'CLEAR_EDIT_ADDRESS_FORM';
export const GET_COORDINATES_EDIT_ADDRESS = 'GET_COORDINATES_EDIT_ADDRESS';


export const UPDATE_COORDINATES_END = 'UPDATE_COORDINATES_END';
export const CLEAR_COORDINATES = 'CLEAR_COORDINATES';

export const OPEN_LIST = 'OPEN_LIST';
export const CLOSE_LIST = 'CLOSE_LIST';
export const OPEN_ATTACHMENT = 'OPEN_ATTACHMENT';
export const CLOSE_ATTACHMENT = 'CLOSE_ATTACHMENT';
export const GET_HOST_LIST = 'GET_HOST_LIST';
export const GET_OPERATOR_INFO = 'GET_OPERATOR_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';

export const OPEN_EDIT_ADDRESS = 'OPEN_EDIT_ADDRESS';
export const CLOSE_EDIT_ADDRESS = 'CLOSE_EDIT_ADDRESS';

export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const OPEN_BRIGADE = 'OPEN_BRIGADE';
export const CLOSE_BRIGADE = 'CLOSE_BRIGADE';
export const OPEN_CREATE_BRIGADE = 'OPEN_CREATE_BRIGADE';
export const CLOSE_CREATE_BRIGADE = 'CLOSE_CREATE_BRIGADE';
export const OPEN_CHANGE_PASSWORD_FORM = 'OPEN_CHANGE_PASSWORD_FORM';
export const CLOSE_CHANGE_PASSWORD_FORM = 'CLOSE_CHANGE_PASSWORD_FORM';
export const CLEAR_CHANGE_PASSWORD_FORM = 'CLEAR_CHANGE_PASSWORD_FORM';

export const PROTOCOL = document.location.protocol;

// export const HOST_NAME = '192.168.178.90';
// export const HOST_PORT = '';
// export const SHOW_MAP_WITH_PROXY = false;
// export const API1 = ':8010';
// export const API2 = ':5012';


export const HOST_NAME = document.location.hostname;
export const HOST_PORT = ':' + document.location.port;
export const SHOW_MAP_WITH_PROXY = true;
export const API1 = '/api1';
export const API2 = '/api2';