import React, { Component } from 'react';
import { connect } from 'react-redux';
import { attachAccount } from '../../actions/setFilter';
import './Attachment.css';
import { withTranslation } from 'react-i18next';

class Attachment extends Component {
  onSubmit = event => {
    event.preventDefault();
    this.props.attachAccount(event.target[0].value, event.target[1].value, event.target[2].value);
  }

  render() {
    const { t } = this.props;

    return (
      <div className='attachment'>
        <form className='attachment_form' onSubmit={ this.onSubmit }>
          <div className='attachment__header'>
            <p>
              { t('У вас нет ни одного прикрепленного аккаунта') }
            </p>
            <p>
              { t('Введите логин и пароль от своей учетной записи в МИС') }
            </p>
          </div>
          <div className='form__group'>
            <label htmlFor='attachment-hostId' className='form__label'>{ t('Хост') }</label>
            <div>
              <select id='attachment-hostId' name='hostId'
                      className='form__input call-submit__select'
                      required={ true } >
                <option value=''>{ t('Выберите хост') }</option>
                {
                  this.props.hosts.map(
                    e => <option value={ e.id } key={ e.id }>{ e.name }</option>
                  )
                }
              </select>
            </div>
          </div>
          <div className='form__group'>
            <label htmlFor='attachment-username' className='form__label'>{ t('Имя пользователя') }</label>
            <input id='attachment-username' name='username'
                   className='form__input'
                   type='text'
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='attachment-password' className='form__label'>{ t('Пароль') }</label>
            <input id='attachment-password' name='password'
                   className='form__input'
                   type='password' />
          </div>
          <div className='form__group'>
            <button type='submit' className='form__btn'>{ t('Прикрепить') }</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hosts: state.oauth.hosts,
    showSearchResult: state.windows.searchResult.show,
  }
}

export default withTranslation()(connect(mapStateToProps, { attachAccount })(Attachment));
