import axios from 'axios';
import { CALL_CHANGE_SEARCH_CLIENT, SEARCH_CLIENT_END, SELECT_CLIENT, CALL_CHANGE_SUBMIT_CLIENT,
         CLEAR_CALL_FORM,RESET_SEARCH_RESULT, GET_COORDINATES_CALL, SUBMIT_CALL_FORM_START, SUBMIT_CALL_FORM_END } from '../constants';
import { SUCCESS, PROTOCOL, HOST_NAME, HOST_PORT, API1, API2 } from '../constants';
import { signout } from './signin';
import { updateList } from './updateList';
import { openSearchResult, closeCallForm, /*openPopup,*/ showSpinner, hideSpinner } from './windows';
//import i18n from '../i18n';

export const changeSearchClient = (name, value) => ({
	type: CALL_CHANGE_SEARCH_CLIENT,
	name,
	value
})

export const changeSubmitClient = (name, value) => ({
  type: CALL_CHANGE_SUBMIT_CLIENT,
  name,
  value
})

export const clearCallForm = () => ({
  type: CLEAR_CALL_FORM
})

const onSuccses = data => {
  return {
    type: SEARCH_CLIENT_END,
    status: SUCCESS,
    data
  }
}

export const searchClient = () => {
  return (dispatch, getState) => {
    const access_token = getState().oauth.data.access_token;
    const token_type = getState().oauth.data.token_type;
    const hostId = getState().oauth.operator.hostId;
    const { lastName, firstName, patrName, birthDate } = getState().callForm.searchClient;

    dispatch(showSpinner());
    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/client/search`,
      {
        headers: {
          Authorization: `${ token_type } ${ access_token }`
        },
        params: {
          hostId,
          lastName,
          firstName,
          patrName,
          birthDate
        }
      }
    ).then(
      response => {
        dispatch(hideSpinner());
        dispatch(onSuccses(response.data))

        if (response.data.length === 1) {
          dispatch(selectClient(response.data[0]));
        }
        if (response.data.length > 1) {
          dispatch(openSearchResult());
        }
      }
    ).catch(
      error => {
        dispatch(hideSpinner());
        if (error.response && error.response.status === 401) {
          dispatch(signout())
        }
      }
    )
  }
}

export const selectClient = client => {
  return (dispatch, getState) => {
    const access_token = getState().oauth.data.access_token;
    const token_type = getState().oauth.data.token_type;
    const hostId = getState().oauth.operator.hostId;

    dispatch(showSpinner());
    axios.get(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API1 }/client/get`,
      {
        headers: {
          Authorization: `${ token_type } ${ access_token }`
        },
        params: {
          hostId,
          clientId: client.id
        }
      }
    ).then(
      response => {
        dispatch(hideSpinner());
        dispatch({
          type: SELECT_CLIENT,
          client: response.data
        })

        dispatch(getCoordinates(response.data))
      }
    ).catch(
      error => {
        dispatch(hideSpinner());
        if (error.response && error.response.status === 401) {
          dispatch(signout())
        }
      }
    )
  }
}

export const getCoordinates = client => {
  return (dispatch, getState) => {
    const address = client.addresses.find(e => e.type === 1) !== undefined ?
                    client.addresses.find(e => e.type === 1).address :
                    null;

    if (address !== null) {
      const city =   address.readableAddress.split(', ')[0].split(' ')[1];
      const street = address.readableAddress.split(', ')[1].split(' ').slice(1, -1).join(' ');
      const house_number = address.number + (address.corpus !== '' ? ' к' + address.corpus : '');

      axios.get(
        `${ PROTOCOL }//${ HOST_NAME + HOST_PORT }/nominatim/search`,
        {
          params: {
            format: 'json',
            addressdetails: 1,
            'accept-language': 'ru',
            countrycodes: 'ru',
            city,
            street: `${ street } ${ house_number }`
          }
        }
      ).then(
        response => {
          const data = response.data.filter(e => e.osm_type === 'way');

          if (data.length > 0) {
            dispatch({
              type: GET_COORDINATES_CALL,
              data: {
                lat: +data[0].lat,
                lon: +data[0].lon
              }
            })
          }
          if (data.length === 0 && response.data.length > 0) {
            dispatch({
              type: GET_COORDINATES_CALL,
              data: {
                lat: +response.data[0].lat,
                lon: +response.data[0].lon
              }
            })
          }
        }
      )
    }
  }
}

export const resetSearchResult = () => ({
  type: RESET_SEARCH_RESULT,
})

export const submitCallForm = () => {
  return (dispatch, getState) => {
    const clientId = getState().callForm.client.id;
    //const today = getState().list.today;

    //if (today.includes(clientId)) {
    //  dispatch(openPopup(i18n.t('Пациент уже записан')));
    //  return;
    //}

    const claim = getState().callForm.submitClient.claim;
    const department = getState().callForm.submitClient.department;
    const note = getState().callForm.submitClient.note;
    const operatorId = getState().oauth.operator.personId;
    const emergencyBrigadeId = getState().callForm.submitClient.emergencyBrigadeId;
    const result = getState().callForm.submitClient.result;

    dispatch({ type: SUBMIT_CALL_FORM_START });
    dispatch(showSpinner());
    axios.post(
      `${ PROTOCOL }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest`,
      emergencyBrigadeId === '' ?
      {
        claim,
        clientId,
        note,
        orgStructureId: +department,
        operatorId
      } :
      {
        claim,
        clientId,
        note,
        orgStructureId: +department,
        emergencyBrigadeId: +emergencyBrigadeId,
        operatorId
      }
    ).then(
      response => {
        const doctorId = getState().callForm.submitClient.doctor;

        if (doctorId !== '') {
          const homeRequestId = response.data.id;

          return axios.post(
            `${ document.location.protocol }//${ HOST_NAME + HOST_PORT + API2 }/homeRequest/appointment`,
            {
              homeRequestId,
              doctorId: +doctorId,
              date: new Date().toJSON().slice(0,10)
            }
          )
        }

        return Promise.resolve();
      }
    ).then(
      response => {
        dispatch({ type: SUBMIT_CALL_FORM_END });
        dispatch(hideSpinner());
        dispatch(updateList());
        dispatch(closeCallForm());
      }
    ).catch(
      error => {
        dispatch({ type: SUBMIT_CALL_FORM_END });
        dispatch(hideSpinner());
        if (error.response && error.response.status === 401) {
          dispatch(signout())
        }
      }
    )
  }
}
