import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signin } from '../../actions/signin';
import { Link } from 'react-router-dom';
import './Signin.css';
import { withTranslation } from 'react-i18next';

import logo from '../../img/logo-vista.svg';

class Signin extends Component {
  onSubmit = event => {
    event.preventDefault();
    this.props.signin(event.target[0].value, event.target[1].value);
  }

  onChange = event => this.setState({ [event.target.name]: event.target.value });

  render() {
    const { t } = this.props;

    return (
      <div className='signin'>
        <form className='signin__form' onSubmit={ this.onSubmit }>
          <div className='form__group'>
            <img className='signin__logo' src={ logo } alt='Виста' />
          </div>
          <div className='form__group'>
            <label htmlFor='username' className='form__label'>{ t('Имя пользователя') }</label>
            <input id='username' name='username'
                   className='form__input'
                   type='text'
                   required={ true } />
          </div>
          <div className='form__group'>
            <label htmlFor='password' className='form__label'>{ t('Пароль') }</label>
            <input id='password' name='password'
                   className='form__input'
                   type='password'
                   required={ true } />
          </div>
          <div className='form__group'>
            <button type='submit' className='form__btn btn--blue'>{ t('Войти') }</button>
          </div>
        </form>
        <div className='sign-footer'>
          <Link to="/signup" className='sign-footer__link'>{ t('Регистрация') }</Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(connect(null, { signin })(Signin));
