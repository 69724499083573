import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeSubmitClient, submitEditForm, copyHomeRequest } from '../../actions/edit';
import { closeEditForm, openConfirmCancel } from '../../actions/windows';
import { withTranslation } from 'react-i18next';
import './Submit.css';

class Submit extends Component {
  state = { showSelectOrgStructure: false, orgStructureId: '' };

  onChange = event => this.props.changeSubmitClient(event.target.name, event.target.value);

  onSubmit = event => {
    event.preventDefault();
    this.props.submitEditForm();
  }

  render() {
    const { t } = this.props;

    const smp = this.props.role === 'SMP_REGISTRATOR';
    const divStyle = {
      height: this.props.request.typeName === 'Отменено' ?
              ( smp ? '45rem' : '35rem' ) :
              ( smp ? '40rem' : '30rem' )
    }
    const formStyle = {
      height: this.props.request.typeName === 'Отменено' ?
              ( smp ? '40rem' : '28rem' ) :
              ( smp ? '35rem' : '23rem' )
    }

    return (
      <div className='call-submit' style={ divStyle }>
        <form className='call-submit__form' onSubmit={ this.onSubmit } style={ formStyle }>
          <div className='form__group'>
            <label htmlFor='edit-claim' className='form__label'>{ t('Жалобы') }</label>
            <input id='edit-claim' name='claim'
                   className='form__input call-submit__input'
                   type='text'
                   value={ this.props.form.claim }
                   onChange={ this.onChange }
                   disabled={ this.props.request.typeName === 'Отменено' } />
          </div>
          <div className='form__group'>
            <label htmlFor='edit-department' className='form__label'>{ t('Отделение') }</label>
            <div className='call-submit__input'>
              <select id='edit-department' name='department'
                      className='form__input call-submit__select'
                      value={ this.props.form.department }
                      onChange={ this.onChange }
                      required={ true }
                      disabled={ this.props.request.typeName === 'Отменено' } >
                {
                  this.props.orgStructures.map(
                    e => <option key={ e.id } value={ e.id }>{ e.code }</option>
                  )
                }
              </select>
            </div>
          </div>
          {
            !smp ?
            <div className='form__group'>
              <label htmlFor='edit-doctor' className='form__label'>{ t('Врач') }</label>
              <div className='call-submit__input'>
                <select id='edit-doctor' name='doctor'
                        className='form__input call-submit__select'
                        value={ this.props.form.doctor }
                        onChange={ this.onChange }
                        disabled={ this.props.request.typeName === 'Отменено' } >
                  <option value=''>{ t('Выберите врача') }</option>
                  {
                    this.props.coordinates.map(
                      e => <option value={ e.id } key={ e.id }>{ e.fio.split(' ')[0] }</option>
                    )
                  }
                </select>
              </div>
            </div> :
            null
          }
          <div className='form__group'>
            <label htmlFor='edit-note' className='form__label'>{ t('Примечание') }</label>
            <input id='edit-note' name='note'
                   className='form__input call-submit__input'
                   type='text'
                   autoComplete='off'
                   value={ this.props.form.note }
                   onChange={ this.onChange }
                   disabled={ this.props.request.typeName === 'Отменено' } />
          </div>
          {
            smp ?
            <div className='form__group'>
              <label className='form__label'>{ t('Принято') }</label>
              <div className='call-submit__input'>
                <input  className='form__datepicker'
                        type={ this.props.form.datetimeOfBrigadeAppointed === '' ? 'text' : 'datetime-local' }
                        value={ this.props.form.datetimeOfBrigadeAppointed }
                        readOnly={ true } />
              </div>
            </div> :
            null
          }
          {
            smp ?
            <div className='form__group'>
              <label className='form__label'>{ t('Передано') }</label>
              <div className='call-submit__input'>
                <input  className='form__datepicker'
                        type={ this.props.form.datetimeOfTransferToOsmp === '' ? 'text' : 'datetime-local' }
                        value={ this.props.form.datetimeOfTransferToOsmp }
                        readOnly={ true } />
              </div>
            </div> :
            null
          }
          {
            smp ?
            <div className='form__group'>
              <label htmlFor='edit-emergency-brigade' className='form__label'>{ t('Номер бригады') }</label>
              <div className='call-submit__input'>
                <select id='edit-emergency-brigade' name='emergencyBrigadeId'
                        className='form__input call-submit__select'
                        value={ this.props.form.emergencyBrigadeId }
                        onChange={ this.onChange } >
                  {
                    this.props.emergencyBrigades.map(
                      e => <option value={ e.id } key={ e.id }>{ e.name }</option>
                    )
                  }
                </select>
              </div>
            </div> :
            null
          }
          {
            smp ?
            <div className='form__group'>
              <label className='form__label'>{ t('Закрыто') }</label>
              <div className='call-submit__input'>
                <input  className='form__datepicker'
                        type={ this.props.form.datetimeOfBrigadeEnd === '' ? 'text' : 'datetime-local' }
                        value={ this.props.form.datetimeOfBrigadeEnd }
                        readOnly={ true } />
              </div>
            </div> :
            null
          }
          { 
            this.props.request.typeName === 'Отменено' ?
            <div className='form__group'>
               <label htmlFor='edit-reason' className='form__label'>{ t('Причина отмены') }</label>
               <input id='edit-reason' name='reason'
                      className='form__input call-submit__input'
                      type='text'
                      value={ this.props.request.reasonOfCancel || '' }
                      disabled={ true } />
            </div> :
            null
          }
          {
            smp ?
            <div className='form__group'>
              <label htmlFor='edit-result' className='form__label'>{ t('Результат') }</label>
              <div className='call-submit__input'>
                <select id='call-result' name='result'
                        className='form__input call-submit__select'
                        value = {this.props.form.result}>
                   <option value=''>{ t('Направлен в стационар') }</option>
                   <option value=''>{ t('Умер') }</option>
                   <option value=''>{ t('Отказался от госпитализации') }</option>
                </select>
              </div>
            </div> :
            null
          }
          <div className='form__group'>
            {
              this.props.request !== undefined && this.props.request.typeName !== 'Отменено' ?
              <button type='submit' className='form__btn'>{ t('Сохранить') }</button> :
              null
            }
            {
              this.props.request !== undefined && this.props.request.typeName !== 'Отменено' ?
              <div className='form__btn' onClick={ () => this.props.openConfirmCancel(this.props.homeRequestId) }>{ t('Отменить') }</div> :
              null
            }
            {
              smp ?
              <div className='form__btn' onClick={ () => this.setState({ showSelectOrgStructure: true }) }>{ t('Копировать') }</div> :
              null
            }
            <div className='form__btn' onClick={ this.props.closeEditForm }>{ t('Назад') }</div>
          </div>
        </form>
        {
          this.state.showSelectOrgStructure ?
          <div className='copy__window'>
            <div className='call-map__close' onClick={ () => this.setState({ showSelectOrgStructure: false }) }>&times;</div>
            <div className='confirm-cancel__text'>
              { t('Выберете отделение') }
            </div>
            <div className='form__group copy__jcse'>
              <label htmlFor='copy-department' className='form__label'>{ t('Отделение') }</label>
              <div className='copy__select'>
                <select id='copy-department' name='department'
                        className='form__input call-submit__select'
                        value={ this.state.orgStructureId }
                        onChange={ e => this.setState({ orgStructureId: e.target.value }) } >
                  <option value=''>{ t('Выберите отделение') }</option>
                  {
                    this.props.orgStructures.map(
                      e => <option key={ e.id } value={ e.id }>{ e.code }</option>
                    )
                  }
                </select>
              </div>
            </div>
            <div className='form__group copy__jcse'>
              <button className='form__btn' onClick={ () => this.props.copyHomeRequest(this.state.orgStructureId) } disabled={ this.state.orgStructureId === '' } >{ t('Копировать') }</button>
              <div className='form__btn' onClick={ () => this.setState({ showSelectOrgStructure: false }) }>{ t('Назад') }</div>
            </div>
          </div> :
          null
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    form: state.editForm.submitClient,
    homeRequestId: state.editForm.homeRequestId,
    coordinates: state.coordinates.data,
    request: state.list.data.find(e => e.id === state.editForm.homeRequestId),
    orgStructures: state.list.orgStructures,
    emergencyBrigades: state.list.emergencyBrigades,
    role: state.oauth.user.type
  }
}

export default withTranslation()(connect(mapStateToProps, { changeSubmitClient, closeEditForm, openConfirmCancel, submitEditForm, copyHomeRequest })(Submit));
